const url = process.env.VUE_APP_API_URL;

export abstract class ApiUrl {
  public static readonly CUSTOMER_INFO = `${url}/customer`;
  public static readonly MAINTENANCE_CHECK = `${url}/maintenance-check`;
  public static readonly FORECAST_REPORT_SUBMIT = `${url}/reportForecast`;
  public static readonly REPORT_SUBMIT = `${url}/reportAmount`;
  public static readonly SEND_MESSAGE = `${url}/sendInquiryMessage`;
  public static readonly UPLOAD_TESTAT = `${url}/uploadTestat`;
  public static readonly DELETE_TESTAT = `${url}/deleteTestat`;
}
