<template>
  <v-card class="px-12 rounded-lg">
    <v-icon
      @click="$emit('close-this')"
      style="
        color: black;
        position: absolute !important;
        right: 16px !important;
        font-size: 2.5rem;
        margin-top: 16px !important;
      "
      >mdi-close-circle-outline</v-icon
    >
    <v-card-title
      class="display-1 font-weight-bold mt-8 pb-8 text-left text-uppercase"
    >
      {{ $t("atomComponentLegalNotice.title") }}
    </v-card-title>
    <v-card-text class="text-justify black--text ln-1 mb-4">
      <p>
        {{ $t("atomComponentLegalNotice.firstParagraph") }}
      </p>
      <p class="mt-8">
        {{ $t("atomComponentLegalNotice.secondParagraph") }}
      </p>
      <p class="mt-8">
        {{ $t("atomComponentLegalNotice.thirdParagraph")
        }}<a href="mailto:presse@zentek.de" class="no-deco">presse@zentek.de</a
        >{{ $t("atomComponentLegalNotice.thirdParagraph2") }}
      </p>
      <p class="mt-8">
        {{ $t("atomComponentLegalNotice.fourthParagraph") }}
      </p>
      <p class="mt-8">
        {{ $t("atomComponentLegalNotice.fifthParagraph") }}
      </p>
      <p class="mt-8">
        {{ $t("atomComponentLegalNotice.sixthParagraph") }}
      </p>
      <p class="mt-8">
        {{ $t("atomComponentLegalNotice.seventhParagraph") }}
      </p>
      <p class="mt-8">
        {{ $t("atomComponentLegalNotice.eigthParagraph") }}
      </p>
    </v-card-text>
  </v-card>
</template>
<script lang="ts">
import Vue from "vue";
export default Vue.extend({
  data: () => {
    return {};
  },
});
</script>
<style lang="sass" scoped>
.ln-1
  line-height: 1.5rem
.label-left
  display: inline-block
  width: 150px
</style>
