import Vue from "vue";
import Vuetify from "vuetify/lib/framework";
import FacebookLogo from "@/components/svg/FacebookLogo.vue";
import InstagramLogo from "@/components/svg/InstagramLogo.vue";
import Sending from "@/components/svg/Sending.vue";
import Raise from "@/components/svg/Raise.vue";
import Drop from "@/components/svg/Drop.vue";
import Equal from "@/components/svg/Equal.vue";
import One from "@/components/svg/One.vue";
import Two from "@/components/svg/Two.vue";
import Three from "@/components/svg/Three.vue";
import Four from "@/components/svg/Four.vue";
import LinkedIn from "@/components/svg/LinkedIn.vue";
import Folder from "@/components/svg/Folder.vue";
import Documents from "@/components/svg/Documents.vue";
import VueI18n from "vue-i18n";
import Language from "@/components/svg/Language.vue";

Vue.use(Vuetify);
Vue.use(VueI18n);

export default new Vuetify({
  theme: {
    themes: {
      light: {
        primary: "#8fedc2",
        secondary: "#52DED9",
        accent: "#F9C07F",
        error: "#F58686",
        info: "#DDDCE2",
        success: "#9CE9C2",
        warning: "#385F73",
        highlight: "#D4F4C7",
        textlight: "#666666",
        highlight2: "#48CEF8",
      },
    },
  },
  icons: {
    values: {
      facebook: {
        component: FacebookLogo,
      },
      instagram: {
        component: InstagramLogo,
      },
      sendButton: {
        component: Sending,
      },
      drop: {
        component: Drop,
      },
      raise: {
        component: Raise,
      },
      equal: {
        component: Equal,
      },
      one: {
        component: One,
      },
      two: {
        component: Two,
      },
      three: {
        component: Three,
      },
      four: {
        component: Four,
      },
      linkedIn: {
        component: LinkedIn,
      },
      folder: {
        component: Folder,
      },
      documents: {
        component: Documents,
      },
      language: {
        component: Language,
      },
    },
  },
});
