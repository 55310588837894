<template>
  <v-card class="mx-0 px-0 pb-16" flat>
    <v-card-title class="headline font-weight-bold px-0 mx-0 pb-6"
      ><v-icon color="black" class="pl-0 pr-4" size="60px">{{
        hasDangerousMaterials ? "$vuetify.icons.four" : "$vuetify.icons.three"
      }}</v-icon>
      {{ $t("atomComponentTestUpload.uploadDeclaration") }}
    </v-card-title>
    <v-card flat class="testat-card">
      <p class="text-md-subtitle-1 font-weight-bold">
        {{ $t("atomComponentTestUpload.addDocuments") }}
      </p>
      <p class="text-md-body-1 text-center">
        {{ $t("atomComponentTestUpload.documentsEx") }}
      </p>
      <v-card flat class="ma-auto main-testat-card">
        <v-card
          flat
          class="ma-auto inner-testat-card text-center"
          v-cloak
          @drop.prevent="addDropFile"
          @dragover.prevent
        >
          <!-- using v-cloak @drop.prevent @dragover.prevent properties in the parent element of file input to enable files dropping -->
          <v-icon class="folder pl-0 pr-4" size="80px" @click="openFolder"
            >$vuetify.icons.folder</v-icon
          >
          <v-btn
            color="white"
            class="upload-button d-block ma-auto"
            rounded
            depressed
            large
            width="30%"
            min-width="250px"
            height="50px"
            @click="openFolder"
            ><v-icon size="20px" class="mr-3">
              $vuetify.icons.documents
            </v-icon>
            {{ $t("atomComponentTestUpload.selectFromFolder") }}</v-btn
          >
          <p class="text-md-subtitle-2 font-weight-bold">
            {{ $t("atomComponentTestUpload.orDragFiles") }}
          </p>
          <p class="text-md-body-1 text-center">
            {{ $t("atomComponentTestUpload.fileFormatPart1") }}<br />
            {{ $t("atomComponentTestUpload.fileFormatPart2") }}
          </p>
          <v-file-input
            :accept="mimes"
            id="filesInput"
            filled
            multiple
            prepend-icon="mdi-plus-circle-outline"
            class="sketch-upload"
            v-model="files"
            style="display: none"
            @change="handleFilesFromFolder"
          ></v-file-input>
        </v-card>
      </v-card>
      <v-card flat class="mt-3 files-detail">
        <v-card-text class="pt-1 pb-1">
          <div
            class="text-left d-inline-block file-info-left"
            v-if="firstFile.name !== ''"
          >
            <v-icon
              color="#8fedc2"
              class="pr-4"
              size="18px"
              v-if="firstFileUploaded && !firstFileFailed"
              >mdi-check-bold</v-icon
            >
            {{ firstFile.name }}
          </div>
          <div
            class="text-right d-inline-block file-info-right"
            v-if="firstFile.name !== ''"
            :class="{ 'red--text': firstFileFailed }"
          >
            {{
              firstFileFailed
                ? firstFileErrorMessage
                : firstFileUploaded
                ? firstFileSize
                : ""
            }}
            <v-progress-linear
              stream
              rounded
              color="#8fedc2"
              v-if="!firstFileUploaded && !firstFileFailed"
              height="6"
            ></v-progress-linear>
            <v-icon
              class="pl-4"
              size="18px"
              :class="firstFileFailed ? 'red--text' : 'grey--text'"
              @click="deleteThisFile(1)"
              v-else
              >mdi-close-thick</v-icon
            >
          </div>
        </v-card-text>
        <v-card-text class="pt-1 pb-1">
          <div
            class="text-left d-inline-block file-info-left"
            v-if="secondFile.name !== ''"
          >
            <v-icon
              color="#8fedc2"
              class="pr-4"
              size="18px"
              v-if="secondFileUploaded && !secondFileFailed"
              >mdi-check-bold</v-icon
            >
            {{ secondFile.name }}
          </div>
          <div
            class="text-right d-inline-block file-info-right"
            v-if="secondFile.name !== ''"
            :class="{ 'red--text': secondFileFailed }"
          >
            {{
              secondFileFailed
                ? secondFileErrorMessage
                : secondFileUploaded
                ? secondFileSize
                : ""
            }}
            <v-progress-linear
              stream
              rounded
              color="#8fedc2"
              v-if="!secondFileUploaded && !secondFileFailed"
              height="6"
            ></v-progress-linear>
            <v-icon
              class="pl-4"
              size="18px"
              :class="secondFileFailed ? 'red--text' : 'grey--text'"
              @click="deleteThisFile(2)"
              v-else
              >mdi-close-thick</v-icon
            >
          </div>
        </v-card-text>
        <v-card-text class="pt-1 pb-1">
          <div
            class="text-left d-inline-block file-info-left"
            v-if="thirdFile.name !== ''"
          >
            <v-icon
              color="#8fedc2"
              class="pr-4"
              size="18px"
              v-if="thirdFileUploaded && !thirdFileFailed"
              >mdi-check-bold</v-icon
            >
            {{ thirdFile.name }}
          </div>
          <div
            class="text-right d-inline-block file-info-right"
            v-if="thirdFile.name !== ''"
            :class="{ 'red--text': thirdFileFailed }"
          >
            {{
              thirdFileFailed
                ? thirdFileErrorMessage
                : thirdFileUploaded
                ? thirdFileSize
                : ""
            }}
            <v-progress-linear
              stream
              rounded
              color="#8fedc2"
              v-if="!thirdFileUploaded && !thirdFileFailed"
              height="6"
            ></v-progress-linear>
            <v-icon
              class="pl-4"
              size="18px"
              :class="thirdFileFailed ? 'red--text' : 'grey--text'"
              @click="deleteThisFile(3)"
              v-else
              >mdi-close-thick</v-icon
            >
          </div>
        </v-card-text>
      </v-card>
    </v-card>
  </v-card>
</template>
<script lang="ts">
import Vue from "vue";
import axios from "axios";
import { ApiUrl } from "@/config/ApiUrl";

function getBase64(input: any) {
  return new Promise(function (resolve, reject) {
    const reader = new FileReader();
    reader.onload = function () {
      resolve(reader.result);
    };
    reader.onerror = reject;
    reader.readAsDataURL(input as any);
  });
}

export default Vue.extend({
  name: "TestatUpload",
  props: {
    hasDangerousMaterials: Boolean,
    customerId: Number,
    businessYear: Number,
    uploadedDocuments: Object,
    customerNumber: String,
    reportId: String,
  },
  data: () => {
    return {
      mimes: "application/pdf",
      files: [] as any,
      firstFile: {
        name: "",
        link: "",
      },
      secondFile: {
        name: "",
        link: "",
      },
      thirdFile: {
        name: "",
        link: "",
      },
      firstFileSize: "",
      secondFileSize: "",
      thirdFileSize: "",
      firstFileUploaded: false,
      secondFileUploaded: false,
      thirdFileUploaded: false,
      firstFileFailed: false,
      secondFileFailed: false,
      thirdFileFailed: false,
      firstFileErrorMessage: "",
      secondFileErrorMessage: "",
      thirdFileErrorMessage: "",
      reportRequest: {} as any,
    };
  },
  created() {
    if (
      this.uploadedDocuments.firstFile &&
      this.uploadedDocuments.firstFile !== undefined &&
      this.uploadedDocuments.firstFile !== ""
    ) {
      this.firstFileUploaded = true;
      this.firstFile.name = this.uploadedDocuments.firstFile.name;
      this.firstFile.link = this.uploadedDocuments.firstFile.link;
      this.firstFileSize = this.uploadedDocuments.firstFile.size;
    }
    if (
      this.uploadedDocuments.secondFile &&
      this.uploadedDocuments.secondFile !== undefined &&
      this.uploadedDocuments.secondFile !== ""
    ) {
      this.secondFileUploaded = true;
      this.secondFile.name = this.uploadedDocuments.secondFile.name;
      this.secondFile.link = this.uploadedDocuments.secondFile.link;
      this.secondFileSize = this.uploadedDocuments.secondFile.size;
    }
    if (
      this.uploadedDocuments.thirdFile &&
      this.uploadedDocuments.thirdFile !== undefined &&
      this.uploadedDocuments.thirdFile !== ""
    ) {
      this.thirdFileUploaded = true;
      this.thirdFile.name = this.uploadedDocuments.thirdFile.name;
      this.thirdFile.link = this.uploadedDocuments.thirdFile.link;
      this.thirdFileSize = this.uploadedDocuments.thirdFile.size;
    }
    this.reportRequest = {
      reportId: this.reportId,
      reportType: 2,
    };
  },
  methods: {
    async addDropFile(e: any) {
      // uploading the dropped files
      const inputFilesAmount = e.dataTransfer.files.length;
      if (
        inputFilesAmount > 3 ||
        (this.firstFileUploaded &&
          this.secondFileUploaded &&
          this.thirdFileUploaded) ||
        (this.firstFileUploaded && inputFilesAmount > 2) ||
        (this.secondFileUploaded && inputFilesAmount > 2) ||
        (this.thirdFileUploaded && inputFilesAmount > 2) ||
        (this.firstFileUploaded &&
          this.secondFileUploaded &&
          inputFilesAmount > 1) ||
        (this.firstFileUploaded &&
          this.thirdFileUploaded &&
          inputFilesAmount > 1) ||
        (this.thirdFileUploaded &&
          this.secondFileUploaded &&
          inputFilesAmount > 1)
      ) {
        this.$toast.error(
          this.$i18n.t("toastTexts.maxThreeDocumentsAllowed") as string
        );
        return false;
      } else {
        await this.processFiles(e.dataTransfer.files);
      }
    },
    async handleFilesFromFolder() {
      if (
        this.files.length > 3 ||
        (this.firstFileUploaded &&
          this.secondFileUploaded &&
          this.thirdFileUploaded) ||
        (this.firstFileUploaded && this.files.length > 2) ||
        (this.firstFileUploaded &&
          this.secondFileUploaded &&
          this.files.length > 1)
      ) {
        this.$toast.error(
          this.$i18n.t("toastTexts.maxThreeDocumentsAllowed") as string
        );
        return false;
      } else {
        await this.processFiles(this.files);
      }
    },
    async processFiles(candidateFiles: any) {
      for (let x = 0; x < candidateFiles.length; x++) {
        if (!this.firstFileUploaded) {
          this.firstFileFailed = false;
          const correctType = this.mimes
            .split(",")
            .find((m) => m === candidateFiles[x].type);
          if (!correctType || candidateFiles[x].size > 5000000) {
            this.firstFileFailed = true;
            this.firstFile.name = candidateFiles[x].name;
            this.firstFileErrorMessage = !correctType
              ? "File type is not supported."
              : "Max. file size 5MB.";
          } else {
            this.firstFile.name = candidateFiles[x].name;
            const formData = new FormData();
            formData.append("customerId", this.customerId.toString());
            formData.append("businessYear", this.businessYear.toString());
            formData.append("file", candidateFiles[x]);
            formData.append("fileLabelNumber", "1");
            await axios({
              url: ApiUrl.UPLOAD_TESTAT,
              method: "POST",
              data: formData,
            })
              .then((apiResponse: any) => {
                if (apiResponse.response && apiResponse.response.data.error) {
                  this.firstFileFailed = true;
                  this.firstFileErrorMessage = apiResponse.response.data.error;
                } else {
                  this.firstFileUploaded = true;
                  const fileSize =
                    candidateFiles[x].size >= 1000000
                      ? candidateFiles[x].size / 1000000
                      : candidateFiles[x].size / 1000;
                  const fileSizeString = fileSize.toLocaleString("de-DE", {
                    maximumFractionDigits: 1,
                  });
                  this.firstFileSize =
                    candidateFiles[x].size >= 1000000
                      ? `${fileSizeString} MB`
                      : `${fileSizeString} KB`;
                  this.firstFile.link = `https://storage.googleapis.com/zentek-digital/mass-signal/testat/${this.customerNumber}-${this.firstFile.name}`;
                }
              })
              .catch((error) => {
                this.firstFileFailed = true;
                this.firstFileErrorMessage = "Failed uploading the file";
              });
          }
        } else if (!this.secondFileUploaded) {
          this.secondFileFailed = false;
          const correctType = this.mimes
            .split(",")
            .find((m) => m === candidateFiles[x].type);
          if (!correctType || candidateFiles[x].size > 5000000) {
            this.secondFileFailed = true;
            this.secondFile.name = candidateFiles[x].name;
            this.secondFileErrorMessage = !correctType
              ? "File type is not supported."
              : "Max. file size 5MB.";
          } else {
            this.secondFile.name = candidateFiles[x].name;
            const formData = new FormData();
            formData.append("customerId", this.customerId.toString());
            formData.append("businessYear", this.businessYear.toString());
            formData.append("file", candidateFiles[x]);
            formData.append("fileLabelNumber", "2");
            await axios({
              url: ApiUrl.UPLOAD_TESTAT,
              method: "POST",
              data: formData,
            })
              .then((apiResponse: any) => {
                if (apiResponse.response && apiResponse.response.data.error) {
                  this.secondFileFailed = true;
                  this.secondFileErrorMessage = apiResponse.response.data.error;
                } else {
                  this.secondFileUploaded = true;
                  const fileSize =
                    candidateFiles[x].size >= 1000000
                      ? candidateFiles[x].size / 1000000
                      : candidateFiles[x].size / 1000;
                  const fileSizeString = fileSize.toLocaleString("de-DE", {
                    maximumFractionDigits: 1,
                  });
                  this.secondFileSize =
                    candidateFiles[x].size >= 1000000
                      ? `${fileSizeString} MB`
                      : `${fileSizeString} KB`;
                  this.secondFile.link = `https://storage.googleapis.com/zentek-digital/mass-signal/testat/${this.customerNumber}-${this.secondFile.name}`;
                }
              })
              .catch((error) => {
                this.secondFileFailed = true;
                this.secondFileErrorMessage = "Failed uploading the file";
              });
          }
        } else if (!this.thirdFileUploaded) {
          this.thirdFileFailed = false;
          const correctType = this.mimes
            .split(",")
            .find((m) => m === candidateFiles[x].type);
          if (!correctType || candidateFiles[x].size > 5000000) {
            this.thirdFileFailed = true;
            this.thirdFile.name = candidateFiles[x].name;
            this.thirdFileErrorMessage = !correctType
              ? "File type is not supported."
              : "Max. file size 5MB.";
          } else {
            this.thirdFile.name = candidateFiles[x].name;
            const formData = new FormData();
            formData.append("customerId", this.customerId.toString());
            formData.append("businessYear", this.businessYear.toString());
            formData.append("file", candidateFiles[x]);
            formData.append("fileLabelNumber", "3");
            await axios({
              url: ApiUrl.UPLOAD_TESTAT,
              method: "POST",
              data: formData,
            })
              .then((apiResponse: any) => {
                if (apiResponse.response && apiResponse.response.data.error) {
                  this.thirdFileFailed = true;
                  this.thirdFileErrorMessage = apiResponse.response.data.error;
                } else {
                  this.thirdFileUploaded = true;
                  const fileSize =
                    candidateFiles[x].size >= 1000000
                      ? candidateFiles[x].size / 1000000
                      : candidateFiles[x].size / 1000;
                  const fileSizeString = fileSize.toLocaleString("de-DE", {
                    maximumFractionDigits: 1,
                  });
                  this.thirdFileSize =
                    candidateFiles[x].size >= 1000000
                      ? `${fileSizeString} MB`
                      : `${fileSizeString} KB`;
                  this.thirdFile.link = `https://storage.googleapis.com/zentek-digital/mass-signal/testat/${this.customerNumber}-${this.thirdFile.name}`;
                }
              })
              .catch((error) => {
                this.thirdFileFailed = true;
                this.thirdFileErrorMessage = "Failed uploading the file";
              });
          }
        } else {
          this.$toast.error(
            this.$i18n.t(
              "toastTexts.maxThreeDocumentsAllowedDeleteFew"
            ) as string
          );
          return false;
        }
      }
    },
    openFolder() {
      const filesInputElement = document.getElementById(
        "filesInput"
      ) as HTMLElement;
      filesInputElement.click();
    },
    async deleteThisFile(fileNumber: number) {
      switch (fileNumber) {
        case 1:
          {
            if (this.firstFile.link !== "") {
              const storageFile = this.firstFile.link.split("zentek-digital/");
              await axios({
                url: ApiUrl.DELETE_TESTAT,
                method: "DELETE",
                data: {
                  customerId: this.customerId,
                  businessYear: this.businessYear,
                  fileLabelNumber: 1,
                  fileName: storageFile[1],
                },
              })
                .then((apiResponse: any) => {
                  this.firstFileUploaded = false;
                  this.firstFileSize = "";
                  this.firstFile.name = "";
                  this.firstFile.link = "";
                })
                .catch((error) => {
                  this.firstFileFailed = true;
                  this.firstFileErrorMessage = "Failed deleting the file";
                });
            } else {
              this.firstFileUploaded = false;
              this.firstFileSize = "";
              this.firstFile.name = "";
              this.firstFile.link = "";
            }
          }
          break;
        case 2:
          {
            if (this.secondFile.link !== "") {
              const storageFile = this.secondFile.link.split("zentek-digital/");
              await axios({
                url: ApiUrl.DELETE_TESTAT,
                method: "DELETE",
                data: {
                  customerId: this.customerId,
                  businessYear: this.businessYear,
                  fileLabelNumber: 2,
                  fileName: storageFile[1],
                },
              })
                .then((apiResponse: any) => {
                  this.secondFileUploaded = false;
                  this.secondFileSize = "";
                  this.secondFile.name = "";
                  this.secondFile.link = "";
                })
                .catch((error) => {
                  this.secondFileFailed = true;
                  this.secondFileErrorMessage = "Failed deleting the file";
                });
            } else {
              this.secondFileUploaded = false;
              this.secondFileSize = "";
              this.secondFile.name = "";
              this.secondFile.link = "";
            }
          }
          break;
        case 3:
          {
            if (this.thirdFile.link !== "") {
              const storageFile = this.thirdFile.link.split("zentek-digital/");
              await axios({
                url: ApiUrl.DELETE_TESTAT,
                method: "DELETE",
                data: {
                  customerId: this.customerId,
                  businessYear: this.businessYear,
                  fileLabelNumber: 3,
                  fileName: storageFile[1],
                },
              })
                .then((apiResponse: any) => {
                  this.thirdFileUploaded = false;
                  this.thirdFileSize = "";
                  this.thirdFile.name = "";
                  this.thirdFile.link = "";
                })
                .catch((error) => {
                  this.thirdFileFailed = true;
                  this.thirdFileErrorMessage = "Failed deleting the file";
                });
            } else {
              this.thirdFileUploaded = false;
              this.thirdFileSize = "";
              this.thirdFile.name = "";
              this.thirdFile.link = "";
            }
          }
          break;
      }
    },
  },
});
</script>
<style scoped>
.testat-card {
  border-radius: 12px !important;
  width: 100%;
  height: 85vh;
  min-height: 590px;
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0),
    0px 5px 8px 0px rgba(0, 0, 0, 0.11), 0px 1px 14px 0px rgba(0, 0, 0, 0.07) !important;
}

.files-detail {
  width: 65%;
  margin: auto;
}

.main-testat-card {
  border-radius: 15px !important;
  background-color: #8fedc2;
  width: 80%;
  height: 45vh;
  margin-top: 5vh !important;
  padding-top: 3.75vh;
  min-height: 333px;
}
.inner-testat-card {
  border-radius: 15px !important;
  border: dashed 2.5px #20dc86;
  background-color: #e8fcf3;
  width: 92%;
  height: 38vh;
  min-height: 281px;
}
.text-md-subtitle-1 {
  color: #212529 !important;
  font-size: 1.15rem !important;
  text-align: center !important;
  padding-top: 4vh;
}
.text-md-subtitle-2 {
  color: #8fedc2 !important;
  font-size: 1.15rem !important;
  text-align: center !important;
  padding-top: 2vh;
}
.upload-button >>> span.v-btn__content {
  color: black;
  text-transform: none;
  font-weight: bold;
}
.upload-button {
  border-radius: 42px;
  border: solid 2px #dedede !important;
  margin-top: 4vh !important;
}
.folder {
  padding-top: 5vh;
}
.theme--light.v-icon:focus::after {
  opacity: 0 !important;
}
.file-info-left {
  width: 65%;
}
.file-info-right {
  width: 35%;
}
</style>
