<template>
  <v-container class="pt-5 px-0 pb-0 main-card">
    <v-container class="px-0 py-0 maintenance-page">
      <h2 class="display-2 font-weight-bold heading">
        <span class="color-primer white-back">{{
          $t("maintenance.title1")
        }}</span>
        <br />
        {{ $t("maintenance.title2") }}
      </h2>
      <div class="cone"></div>
      <p class="attention-text mt-8" style="margin-bottom: 8px !important">
        {{ $t("maintenance.paragraph1") }}
      </p>
      <p class="attention-text">{{ $t("maintenance.paragraph2") }}</p>
      <p class="attention-text">
        <span class="color-primer">{{
          isTvp ? $t("maintenance.phoneTvp") : $t("maintenance.phoneWeee")
        }}</span>
        <br />
        <span style="font-size: 0.9rem"
          ><sup style="top: -1.5em">{{
            isTvp
              ? $t("maintenance.openingsTvp")
              : $t("maintenance.openingsWeee")
          }}</sup></span
        >
        <br />
        <a
          class="color-primer"
          style="text-decoration: none !important"
          :href="`mailto:${
            isTvp ? 'tvp-mengenmeldung@zentek.de' : 'weee_ear@zentek.de'
          }`"
          >{{
            isTvp ? $t("maintenance.emailTvp") : $t("maintenance.emailWeee")
          }}</a
        >
      </p>
    </v-container>
  </v-container>
</template>
<script lang="ts">
import Vue from "vue";

export default Vue.extend({
  name: "MaintenancePage",
  props: {
    isTvp: Boolean,
  },
});
</script>
<style lang="sass" scoped>
.maintenance-page
  height: 90vh
  width: 75%
  margin-left: 12.5%
.heading
  line-height: 4rem
  text-align: center
  margin: 20px 0 70px 0
.cone
  width: 201.342px
  height: 222.419px
  margin: 0 auto
  background-image: url("../assets/Cone_Maintenance.svg")
  background-repeat: no-repeat
  background-position: center
  background-size: 100% auto
.attention-text
  text-align: center
  font-size: 1.25rem !important

@media (min-height: 820px)
  .maintenance-page
    height: 80vh !important
</style>
