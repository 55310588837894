import { render, staticRenderFns } from "./Language.vue?vue&type=template&id=546b6c82&scoped=true"
var script = {}
import style0 from "./Language.vue?vue&type=style&index=0&id=546b6c82&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "546b6c82",
  null
  
)

export default component.exports