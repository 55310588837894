import Vue from "vue";
import Vuex from "vuex";
import { appModule } from "@/store/app";
import { customerModule } from "@/store/customer";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {},
  mutations: {},
  actions: {},
  modules: {
    app: appModule,
    customerManagement: customerModule,
  },
  getters: {},
});
