import axios from "axios";
import { ApiUrl } from "@/config/ApiUrl";

export const customerModule = {
  state: () => ({
    customer: {
      name: "",
      address: {
        street: "",
        zip: "",
        city: "",
        country: "",
      },
      id: 0,
      contact: {
        email: "",
        phone: "",
      },
      customerNumber: "",
      manufacturerNumber: "",
      referenceNumber: "",
      reportType: "",
      weeeRegisterNumber: "",
      materials: null as any,
    },
    report: null as any,
    notification: {
      reportId: "",
      businessYear: 0,
      reportType: "",
      productType: "",
      monthId: 0,
      quarterId: 0,
      done: "",
      submitDate: "",
    },
    expired: false,
    valid: false,
    responseFromBackend: false,
    inMaintenance: false,
  }),
  mutations: {
    authSuccess(state: any, payload: any) {
      state.notification.reportId = payload.notification.reportId;
      state.notification.businessYear = payload.notification.businessYear;
      state.notification.reportType = payload.notification.reportType;
      state.notification.productType = payload.notification.productType;
      if (
        payload.notification.monthId &&
        payload.notification.monthId !== null
      ) {
        state.notification.monthId = payload.notification.monthId;
      }
      if (
        payload.notification.quarterId &&
        payload.notification.quarterId !== null
      ) {
        state.notification.quarterId = payload.notification.quarterId;
      }
      state.notification.done = payload.notification.done;
      state.notification.submitDate = payload.notification.submitDate;
      if (payload.isExpired) {
        state.customer.address.country = payload.customer.address.country;
        state.expired = true;
      } else {
        state.customer.id = payload.customer.customerId;
        state.customer.name = payload.customer.name;
        state.customer.address.street = payload.customer.address.street;
        state.customer.address.zip = payload.customer.address.zip;
        state.customer.address.city = payload.customer.address.city;
        state.customer.address.country = payload.customer.address.country;
        state.customer.contact.email = payload.customer.contact.email;
        state.customer.contact.phone = payload.customer.contact.phone;
        state.customer.customerNumber = payload.customer.customerNumber;
        state.customer.manufacturerNumber = payload.customer.manufacturerNumber
          ? payload.customer.manufacturerNumber
          : payload.customer.customerNumber;
        state.customer.referenceNumber = payload.customer.referenceNumber;
        state.customer.weeeRegisterNumber = payload.customer.weeeRegisterNumber;
        state.customer.materials = payload.customer.materials;
        state.customer.reportType = payload.customer.reportType;
        state.report = payload.report;
        state.valid = true;
      }
    },
    maintenanceMode(state: any, payload: any) {
      state.inMaintenance = payload;
    },
    markResponseFromBackend(state: any) {
      state.responseFromBackend = true;
    },
  },
  actions: {
    loadCustomerData({ commit, dispatch }: any, payload: any) {
      return new Promise((resolve, reject) => {
        axios({
          url: `${ApiUrl.CUSTOMER_INFO}/${payload.reportId}/${payload.reportType}`,
          method: "GET",
        })
          .then((apiResponse: any) => {
            if (
              apiResponse.request.status === 401 ||
              apiResponse.request.status === 404 ||
              (apiResponse.response &&
                (apiResponse.response.data.status === 404 ||
                  apiResponse.response.data.statusCode === 404))
            ) {
              commit("markResponseFromBackend");
              reject(apiResponse);
              return apiResponse;
            } else if (
              apiResponse.data.inMaintenance &&
              apiResponse.data.inMaintenance === true
            ) {
              commit("maintenanceMode", true);
              commit("markResponseFromBackend");
              resolve(apiResponse);
            } else {
              const customer = apiResponse.data;
              commit("authSuccess", customer);
              commit("maintenanceMode", false);
              commit("markResponseFromBackend");
              resolve(apiResponse);
            }
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    checkMaintenanceStatus({ commit, dispatch }: any, payload: any) {
      return new Promise((resolve, reject) => {
        axios({
          url: `${ApiUrl.MAINTENANCE_CHECK}`,
          method: "GET",
        })
          .then((apiResponse: any) => {
            if (
              apiResponse.request.status === 401 ||
              apiResponse.request.status === 404 ||
              (apiResponse.response &&
                (apiResponse.response.data.status === 404 ||
                  apiResponse.response.data.statusCode === 404))
            ) {
              reject(apiResponse);
              return apiResponse;
            } else if (apiResponse.data) {
              commit("maintenanceMode", apiResponse.data);
              resolve(apiResponse.data.inMaintenance);
            }
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
  },
  getters: {
    customer(state: any) {
      return state.customer;
    },
    report(state: any) {
      return state.report;
    },
    notification(state: any) {
      return state.notification;
    },
    valid(state: any) {
      return state.valid;
    },
  },
};
