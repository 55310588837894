<template>
  <v-container
    class="table-container ma-0 pa-0 pt-16"
    v-if="report && originalReportType"
  >
    <v-card
      class="mx-0 px-0 pb-16"
      flat
      v-if="nonDangerousMaterials.length && nonDangerousMaterials.length > 0"
    >
      <v-card-title class="headline font-weight-bold px-0 mx-0 pb-6"
        ><v-icon color="black" class="pl-0 pr-4" size="60px"
          >$vuetify.icons.two</v-icon
        >
        {{ $t("DashboardSectionTwoHeading.packagingMaterial") }}
        <span class="colored-text">
          {{ $t("DashboardSectionTwoHeading.without") }} </span
        >{{ $t("DashboardSectionTwoHeading.hazardousResidues") }}
      </v-card-title>
      <v-data-table
        :headers="tvpHeaders"
        :items="nonDangerousMaterials"
        item-key="materialId"
        :items-per-page="-1"
        class="elevation-4 amount-table"
        :hide-default-footer="true"
      >
        <template v-slot:body="{ items }">
          <tbody>
            <tr v-for="item in items" :key="item.materialId">
              <td class="text-md-body-1 pl-10">
                {{ $t(`materials.${item.materialNumber}`) }}
              </td>
              <td class="text-md-body-1 text-center amount-table-border">
                {{ item.avv }}
              </td>
              <template v-for="reportMaterial in report.materialVolumes">
                <td
                  class="text-md-body-1 pl-4 pr-8 text-right amount-table-border"
                  v-if="reportMaterial.materialId === item.materialId"
                  :key="reportMaterial.materialId"
                >
                  {{
                    reportMaterial.forecastAmount &&
                    reportMaterial.forecastAmount !== null
                      ? localize(
                          reportMaterial.forecastAmount.toLocaleString(locale)
                        )
                      : "0"
                  }}
                  {{ reportMaterial.unit }}
                </td>
              </template>
              <td
                class="text-md-body-1 text-center"
                v-if="materialAmounts.length > 0"
              >
                <template v-for="materialAmount in materialAmounts">
                  <v-text-field
                    v-model="materialAmount.amount"
                    class="text-md-body-1 text-right text-field-td"
                    :rules="decimalRules"
                    dense
                    outlined
                    flat
                    solo
                    suffix="t"
                    hide-details="auto"
                    v-if="materialAmount.materialId === item.materialId"
                    :key="materialAmount.materialId"
                    @input="calculateDifference(materialAmount)"
                    :placeholder="numberPlaceholder"
                    @keypress.native="checkUserInput"
                    @focus="handleFocus"
                  ></v-text-field>
                </template>
              </td>
              <td
                class="text-md-body-1 pl-4 pr-8 difference-td font-weight-bold"
              >
                <template v-for="materialAmount in materialAmounts">
                  <v-col
                    cols="12"
                    class="pr-0 pl-2 py-0 d-flex"
                    v-if="materialAmount.materialId === item.materialId"
                    :key="materialAmount.materialId"
                  >
                    <v-col cols="2" class="px-0 py-0 text-left">
                      <v-icon size="24px" v-if="materialAmount.difference > 0">
                        $vuetify.icons.raise
                      </v-icon>
                      <v-icon
                        size="24px"
                        v-if="materialAmount.difference === 0"
                      >
                        $vuetify.icons.equal
                      </v-icon>
                      <v-icon size="24px" v-if="materialAmount.difference < 0">
                        $vuetify.icons.drop
                      </v-icon>
                    </v-col>
                    <v-col cols="10" class="px-0 py-0 d-inline text-right">
                      {{ localizeSum(Math.abs(materialAmount.difference)) }}
                      {{ materialAmount.unit }}
                    </v-col>
                  </v-col>
                </template>
              </td>
            </tr>
          </tbody>
        </template>
      </v-data-table>
      <span class="hint-text mx-0 mt-2 text-right pr-0 d-block">
        {{ $t("decimalHint") }}
      </span>
    </v-card>
    <v-card
      class="mx-0 px-0"
      flat
      :class="
        !nonDangerousMaterials.length || nonDangerousMaterials.length === 0
          ? 'pb-16'
          : 'py-16'
      "
      v-if="dangerousMaterials.length && dangerousMaterials.length > 0"
    >
      <v-card-title class="headline font-weight-bold px-0 mx-0"
        ><v-icon color="black" class="pl-0 pr-4" size="60px">{{
          !nonDangerousMaterials.length || nonDangerousMaterials.length === 0
            ? "$vuetify.icons.two"
            : "$vuetify.icons.three"
        }}</v-icon>
        {{ $t("DashboardSectionTwoHeading.packagingMaterial") }}
        <span class="colored-text">
          {{ $t("DashboardSectionTwoHeading.with") }} </span
        >{{ $t("DashboardSectionTwoHeading.hazardousResiduesn") }}
      </v-card-title>
      <v-data-table
        :headers="tvpHeaders"
        :items="dangerousMaterials"
        item-key="id"
        :items-per-page="-1"
        class="elevation-4 amount-table"
        :hide-default-footer="true"
      >
        <template v-slot:body="{ items }">
          <tbody>
            <tr v-for="item in items" :key="item.materialId">
              <td class="text-md-body-1 pl-10">
                {{ $t(`materials.${item.materialNumber}`) }}
              </td>
              <td class="text-md-body-1 text-center amount-table-border">
                {{ item.avv }}
              </td>
              <template v-for="reportMaterial in report.materialVolumes">
                <td
                  class="text-md-body-1 pl-4 pr-8 text-right amount-table-border"
                  v-if="reportMaterial.materialId === item.materialId"
                  :key="reportMaterial.materialId"
                >
                  {{
                    reportMaterial.forecastAmount &&
                    reportMaterial.forecastAmount !== null
                      ? localize(
                          reportMaterial.forecastAmount.toLocaleString(locale)
                        )
                      : "0"
                  }}
                  {{ reportMaterial.unit }}
                </td>
              </template>
              <td
                class="text-md-body-1 text-center"
                v-if="materialAmounts.length > 0"
              >
                <template v-for="materialAmount in materialAmounts">
                  <v-text-field
                    v-model="materialAmount.amount"
                    class="text-md-body-1 text-right text-field-td"
                    :rules="decimalRules"
                    dense
                    outlined
                    flat
                    solo
                    suffix="t"
                    hide-details="auto"
                    v-if="materialAmount.materialId === item.materialId"
                    :key="materialAmount.materialId"
                    @input="calculateDifference(materialAmount)"
                    :placeholder="numberPlaceholder"
                    @keypress.native="checkUserInput"
                    @focus="handleFocus"
                  ></v-text-field>
                </template>
              </td>
              <td
                class="text-md-body-1 pl-2 text-center difference-td font-weight-bold"
              >
                <template v-for="materialAmount in materialAmounts">
                  <v-col
                    cols="12"
                    class="pr-0 pl-2 py-0 d-flex"
                    v-if="materialAmount.materialId === item.materialId"
                    :key="materialAmount.materialId"
                  >
                    <v-col cols="2" class="px-0 py-0 text-left">
                      <v-icon size="24px" v-if="materialAmount.difference > 0">
                        $vuetify.icons.raise
                      </v-icon>
                      <v-icon
                        size="24px"
                        v-if="materialAmount.difference === 0"
                      >
                        $vuetify.icons.equal
                      </v-icon>
                      <v-icon size="24px" v-if="materialAmount.difference < 0">
                        $vuetify.icons.drop
                      </v-icon>
                    </v-col>
                    <v-col cols="10" class="px-0 py-0 d-inline text-right">
                      {{ localizeSum(Math.abs(materialAmount.difference)) }}
                      {{ materialAmount.unit }}
                    </v-col>
                  </v-col>
                </template>
              </td>
            </tr>
          </tbody>
        </template>
      </v-data-table>
      <v-row>
        <v-col cols="6" class="hint-text mx-0 mt-2 text-left"
          >*{{ $t("DashboardSectionTwoHeading.with") }}
          {{ $t("DashboardSectionTwoHeading.hazardousResiduesn") }}</v-col
        >
        <v-col cols="6" class="hint-text mt-2 text-right mr-0 ml-auto">
          {{ $t("decimalHint") }}
        </v-col>
      </v-row>
    </v-card>
    <testat-upload
      :hasDangerousMaterials="dangerousMaterials.length > 0"
      :customerId="customerId"
      :customerNumber="customerNumber"
      :businessYear="businessYear"
      :reportId="reportId"
      :uploadedDocuments="report.documentLinks ? report.documentLinks : {}"
    />
    <div class="button-width mx-auto my-12 px-0 py-16">
      <v-btn
        color="primary"
        class="send-button"
        rounded
        depressed
        x-large
        width="100%"
        height="65px"
        @click="checkForZero"
        :disabled="infoEditMode || !safeToSave"
        ><v-icon size="40px" class="mr-2"> $vuetify.icons.sendButton </v-icon>
        {{ $t("btnReportQuantities") }}</v-btn
      >
      <span class="hint-text hint-middle mx-auto mt-4 text-center d-block">{{
        $t("btnClickDescription")
      }}</span>
    </div>
    <loading-component :isLoading="submitClicked" />
    <v-dialog v-model="isZero" max-width="600" overlay-color="white" persistent>
      <zero-warning @submit-zero="sendData" @close-me="isZero = false" />
    </v-dialog>
  </v-container>
</template>
<script lang="ts">
import Vue from "vue";
import {
  germanDecimalRules,
  convertLocalNumberStringToFloat,
  englishDecimalRules,
  validateGermanDecimals,
  validateEnglishDecimals,
} from "../helpers";
import { mapState } from "vuex";
import axios from "axios";
import { ApiUrl } from "@/config/ApiUrl";
import TestatUpload from "@/components/atoms/TestatUpload.vue";
import LoadingComponent from "@/components/atoms/LoadingComponent.vue";
import ZeroWarning from "./atoms/ZeroWarning.vue";

interface YearlyReportRequest {
  customerId: number;
  reportId: string;
  reportType: string;
  productType: string;
  materialVolumes: [
    {
      materialId: number;
      unit: string;
      amount: number;
    }
  ];
  businessYear: number;
  submittedDate: string;
}
export default Vue.extend({
  name: "Yearly",
  props: {
    infoEditMode: Boolean,
  },
  components: {
    TestatUpload,
    LoadingComponent,
    ZeroWarning,
  },
  data: () => {
    return {
      inputMaterials: [] as any,
      tvpHeaders: [
        /* header for TVP Tables*/
        {
          text: "Verpackungsmaterial",
          align: "start",
          class: "amount-table-header pl-10",
          sortable: false,
        },
        {
          text: "AVV",
          align: "center",
          class: "amount-table-header amount-table-border",
          sortable: false,
        },
        {
          text: "Prognose / Jahr ",
          align: "center",
          class: "amount-table-header amount-table-border",
          sortable: false,
        },
        {
          text: "IST-Menge / Jahr ",
          align: "center",
          class: "amount-table-header",
          sortable: false,
        },
        {
          text: "Differenz",
          align: "center",
          class: "amount-table-header",
          sortable: false,
        },
      ],
      materialAmounts: [] as any,
      submitClicked: false,
      isZero: false,
    };
  },
  computed: {
    ...mapState({
      customerId: (state: any) => state.customerManagement.customer.id,
      customerNumber: (state: any) =>
        state.customerManagement.customer.customerNumber,
      reportType: (state: any) =>
        state.customerManagement.notification.reportType,
      productType: (state: any) =>
        state.customerManagement.notification.productType,
      materials: (state: any) => state.customerManagement.customer.materials,
      report: (state: any) => state.customerManagement.report,
      businessYear: (state: any) =>
        state.customerManagement.notification.businessYear,
      reportId: (state: any) => state.customerManagement.notification.reportId,
      originalReportType: (state: any) =>
        state.customerManagement.customer.reportType,
    }),
    dangerousMaterials() {
      let dangerousMaterials = [] as any;
      dangerousMaterials = this.materials.filter((material: any) => {
        return material.avv.includes("*");
      });
      return dangerousMaterials;
    },
    nonDangerousMaterials() {
      let nonDangerousMaterials = [] as any;
      nonDangerousMaterials = this.materials.filter((material: any) => {
        return !material.avv.includes("*");
      });
      return nonDangerousMaterials;
    },
    locale() {
      if (this.$i18n.locale === "en") {
        return "en-GB";
      }
      return "de-DE";
    },
    numberPlaceholder() {
      if (this.$i18n.locale === "en") {
        return "0.000";
      }
      return "0,000";
    },
    decimalRules() {
      if (this.$i18n.locale === "en") {
        return englishDecimalRules;
      }
      return germanDecimalRules;
    },
    safeToSave() {
      let isValid = true;
      for (const materialAmount of this.materialAmounts) {
        const singleValid =
          this.$i18n.locale === "de"
            ? validateGermanDecimals(materialAmount.amount)
            : validateEnglishDecimals(materialAmount.amount);
        if (!singleValid) {
          isValid = false;
        }
      }
      return isValid;
    },
  },
  mounted() {
    const materialAmounts = [] as any;
    for (const material of this.report.materialVolumes) {
      const materialAmount = {
        materialId: material.materialId,
        amount: "",
        forecastAmount: material.forecastAmount,
        difference: 0,
        unit: "t",
      };
      materialAmounts.push(materialAmount);
    }

    this.materialAmounts = materialAmounts;
    this.tvpHeaders[0].text = this.$i18n.t(
      "DashboardTableHeader.packagingMaterial"
    ) as string;
    this.tvpHeaders[1].text = this.$i18n.t(
      "DashboardTableHeader.avv"
    ) as string;
    this.tvpHeaders[2].text = `${
      this.reportType !== this.originalReportType
        ? (this.$i18n.t("specialHeader.TotalMonths") as string)
        : (this.$i18n.t("DashboardTableHeader.forecastYear") as string)
    } ${this.businessYear}`;
    this.tvpHeaders[3].text = `${
      this.reportType !== this.originalReportType
        ? (this.$i18n.t("specialHeader.TotalAmounts") as string)
        : (this.$i18n.t("DashboardTableHeader.actualQuantity") as string)
    } ${this.businessYear}`;
    this.tvpHeaders[4].text = this.$i18n.t(
      "DashboardTableHeader.diff"
    ) as string;
  },
  watch: {
    "$i18n.locale": function (newVal, oldVal) {
      this.tvpHeaders[0].text = this.$i18n.t(
        "DashboardTableHeader.packagingMaterial"
      ) as string;
      this.tvpHeaders[1].text = this.$i18n.t(
        "DashboardTableHeader.avv"
      ) as string;
      this.tvpHeaders[2].text = `${
        this.reportType !== this.originalReportType
          ? (this.$i18n.t("specialHeader.TotalMonths") as string)
          : (this.$i18n.t("DashboardTableHeader.forecastYear") as string)
      } ${this.businessYear}`;
      this.tvpHeaders[3].text = `${
        this.reportType !== this.originalReportType
          ? (this.$i18n.t("specialHeader.TotalAmounts") as string)
          : (this.$i18n.t("DashboardTableHeader.actualQuantity") as string)
      } ${this.businessYear}`;
      this.tvpHeaders[4].text = this.$i18n.t(
        "DashboardTableHeader.diff"
      ) as string;
    },
  },
  methods: {
    localize(value: any) {
      if (this.$i18n.locale === "en") {
        return value.replace(/,/g, "");
      } else {
        return value.replace(/\./g, "");
      }
    },
    localizeSum(value: number) {
      let stringValue = value.toLocaleString(this.locale, {
        minimumFractionDigits: 1,
        maximumFractionDigits: 3,
      });
      if (this.$i18n.locale === "en") {
        stringValue = stringValue.replace(/,/g, "");
      } else {
        stringValue = stringValue.replace(/\./g, "");
      }
      return stringValue;
    },
    checkUserInput($userInput: any) {
      const keyCode = $userInput.keyCode ? $userInput.keyCode : $userInput.key;

      // allow comma: 44 and dot: 46
      if (keyCode !== 44 && keyCode !== 46) {
        // allow numbers
        if (keyCode < 48 || keyCode > 57) {
          $userInput.preventDefault();
        }
      }
    },
    handleFocus() {
      if (this.infoEditMode !== false) {
        this.$vuetify.goTo("#customerInfo");
        this.$toast.error(
          this.$t("toastTexts.saveOrDiscardChangesBeforeContinuing") as string
        );
      }
    },
    checkForZero() {
      let zeroAmount = true;
      for (const materialAmount of this.materialAmounts) {
        if (
          materialAmount.amount !== "0" &&
          materialAmount.amount !== "" &&
          materialAmount.amount !== "0.000" &&
          materialAmount.amount !== "0,000"
        ) {
          zeroAmount = false;
          break;
        }
      }
      if (zeroAmount !== false) {
        this.isZero = true;
      } else {
        this.sendData();
      }
    },
    sendData() {
      this.isZero = false;
      this.submitClicked = true;
      console.log(this.submitClicked);
      const finalMaterialAmounts = JSON.parse(
        JSON.stringify(this.materialAmounts)
      );
      for (const materialAmount of finalMaterialAmounts) {
        materialAmount.amount = convertLocalNumberStringToFloat(
          this.locale,
          materialAmount.amount === "" ? "0" : materialAmount.amount
        );
        delete materialAmount.difference;
      }
      const payload: YearlyReportRequest = {
        customerId: this.customerId,
        reportId: this.reportId,
        reportType: this.reportType,
        productType: this.productType,
        materialVolumes: finalMaterialAmounts,
        businessYear: this.businessYear,
        submittedDate: new Date().toLocaleDateString("de-DE", {
          day: "2-digit",
          month: "2-digit",
          year: "numeric",
        }),
      };
      /* AXIOS POST REQUEST HERE */
      this.$store.dispatch("checkMaintenanceStatus").then((status) => {
        if (status !== true) {
          axios({
            url: ApiUrl.REPORT_SUBMIT,
            method: "POST",
            data: payload,
          })
            .then((apiResponse: any) => {
              if (apiResponse.response && apiResponse.response.data.error) {
                this.submitClicked = false;
                this.$toast.error(
                  this.$t("toastTexts.internalError") as string
                );
                return false;
              } else {
                this.submitClicked = false;
                this.$emit("successfully-submitted");
              }
            })
            .catch((error) => {
              this.submitClicked = false;
              this.$toast.error(this.$t("toastTexts.internalError") as string);
            });
        } else {
          window.location.reload();
        }
      });
    },
    convertMe(numberString: string) {
      return convertLocalNumberStringToFloat(this.locale, numberString);
    },
    calculateDifference(materialAmount: any) {
      let amountString =
        materialAmount.amount === ""
          ? "0"
          : materialAmount.amount.replace(/,/g, ".");
      const difference =
        parseFloat(amountString) - materialAmount.forecastAmount;
      materialAmount.difference = difference;
    },
  },
});
</script>
<style scoped>
.table-container {
  width: 100%;
}
.text-field-td {
  width: 70%;
  margin-left: auto;
  margin-right: auto;
}
.text-field-td >>> input {
  text-align: right;
}
.text-field-td >>> .v-text-field__suffix {
  color: grey;
}
.text-field-td.v-text-field--outlined:not(.v-input--is-focused) >>> fieldset {
  border-color: rgba(0, 0, 0, 0.1);
  box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.1);
}
.text-field-td >>> .v-text-field__details {
  margin-bottom: 0px;
  max-width: 100%;
  width: 100%;
  font-size: 0.3rem !important;
}
.text-field-td >>> .v-messages {
  font-size: 11px;
  height: 11px;
}
.button-width {
  width: 40%;
}
.send-button >>> span.v-btn__content {
  color: black;
  text-transform: none;
  font-weight: bold;
}
.send-button {
  border-radius: 42px;
}
.button-width .hint-middle {
  width: 80%;
}
</style>
