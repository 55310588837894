<template>
  <v-container class="ma-0 pa-0" :class="{ 'blubb-logo-top': !isMobile }">
    <div style="height: 24vh; width: 100%; margin-left: 6vw"></div>
    <h1 class="display-2 font-weight-bold heading">
      <span class="color-primer white-back">{{
        $t("WrongPage.headingGreen")
      }}</span>
      {{ $t("WrongPage.headingBlack") }}
    </h1>
    <p class="attention-text">{{ $t("WrongPage.attentionText") }}</p>
  </v-container>
</template>
<script lang="ts">
import Vue from "vue";

export default Vue.extend({
  name: "WrongPage",
  computed: {
    isMobile() {
      return this.$vuetify.breakpoint.width < 768;
    },
  },
});
</script>
<style lang="sass" scoped>
.blubb-logo-top
  margin-top: -74px !important
  min-width: 100%
  min-height: 100vh
  margin-bottom: 150px !important
  background-image: url("../assets/wrongpage.png")
  background-repeat: no-repeat
  background-size: cover
.heading
  line-height: 1
  margin-left: 25px
  text-align: center
  margin: 20px 0 40px 0
.plug-image
  background-image: url("../assets/Stecker.svg")
  background-repeat: no-repeat
  background-position: center
  background-size: 80% auto
.attention-text
  text-align: center
  font-size: 1.5rem !important
</style>
