<template>
  <v-card class="pa-6">
    <p class="headline font-weight-bold mt-4 pb-4 text-center">
      {{ $t("zeroWarningTitle") }}
    </p>
    <p class="text-md-body-1 text-center">
      {{ $t("zeroWarningLineOne") }} <br />
      {{ $t("zeroWarningLineTwo") }}
    </p>

    <v-card-actions class="warning-button">
      <v-btn
        color="primary"
        @click="$emit('close-me')"
        class="send-zero-button"
        rounded
        depressed
        height="50px"
        width="200px"
      >
        {{ $t("nope") }}</v-btn
      >

      <v-btn
        color="primary"
        @click="$emit('submit-zero')"
        class="send-zero-button"
        rounded
        depressed
        outlined
        height="50px"
        width="200px"
      >
        {{ $t("yesSubmitZero") }}</v-btn
      >
    </v-card-actions>
  </v-card>
</template>

<script>
import Vue from "vue";

export default Vue.extend({
  name: "ZeroWarning",
});
</script>

<style scoped>
.warning-button {
  display: flex;
  max-width: 90%;
  margin-left: 5%;
  margin-top: 32px;
  justify-content: space-around;
}
.send-zero-button {
  border-radius: 42px;
}
.send-zero-button >>> span.v-btn__content {
  color: black;
  text-transform: none;
  font-weight: bold;
  font-size: 1rem;
}
</style>
