<template>
  <v-container class="pa-0 pt-5 main-card" id="mainMass">
    <v-container class="mass-info pa-0">
      <h1 class="display-1 font-weight-bold">
        {{ $t("mengenmeldung") }}
        <span class="color-primer"> - {{ detailInfo }}</span>
      </h1>
      <br />
      <p
        v-for="(paragraph, i) in paragraphs"
        :key="i"
        class="text-md-body-1 text-lg-justify"
      >
        {{ paragraph }}
      </p>
      <p
        class="text-md-body-1 text-lg-justify"
        v-if="this.reportType === 3 && this.isTvp"
      >
        <span>{{ $t("timeToReport.paragraph1") }}</span>
      </p>
    </v-container>
    <v-container class="mass-content px-0 py-16">
      <v-container class="ma-0 pa-0 pt-16" width="100%" id="customerInfo">
        <v-card class="mx-0 px-0 pb-14" flat>
          <div>
            <v-card-title
              class="headline font-weight-bold d-inline-block px-0 mx-0 pb-6"
              ><v-icon color="black" class="pl-0 pr-4" size="60px"
                >$vuetify.icons.one</v-icon
              >
              {{ $t("masterData.checkMasterData") }}
            </v-card-title>
            <v-btn
              text
              class="d-inline-block ml-7"
              color="primary"
              @click="switchEditMode"
              v-if="!editMode"
              >{{ $t("masterData.change") }}</v-btn
            >
          </div>
          <v-divider class="title-divider" />
          <v-col cols="12" class="px-0 pt-8 pb-0 d-flex">
            <v-col cols="6" class="pl-0 py-0 text-left d-block">
              <div>
                <v-card-text
                  class="pl-0 pb-1 font-weight-bold text-md-body-1 company-label-left d-inline-block"
                >
                  {{ $t("masterData.companyName") }}
                </v-card-text>
                <v-card-text
                  class="pb-1 text-md-body-1 company-data-left d-inline-block"
                >
                  {{ customer.name }}
                </v-card-text>
              </div>
              <div>
                <v-card-text
                  class="pl-0 pb-1 font-weight-bold text-md-body-1 company-label-left d-inline-block"
                >
                  {{ $t("masterData.address") }}
                </v-card-text>
                <v-card-text
                  class="pb-1 text-md-body-1 company-data-left d-inline-block"
                >
                  {{ customer.address.street }}<br />{{
                    customer.address.zip + " " + customer.address.city
                  }}
                </v-card-text>
              </div>
              <div>
                <v-card-text
                  v-if="!editMode"
                  class="pl-0 pb-1 font-weight-bold text-md-body-1 company-label-left d-inline-block"
                >
                  {{ $t("masterData.email") }}
                </v-card-text>
                <v-card-text
                  v-if="editMode"
                  class="pl-0 pb-1 font-weight-bold text-md-body-1 company-label-left d-inline-block"
                >
                  {{ $t("masterData.email") }}*
                </v-card-text>
                <v-card-text
                  class="pb-1 text-md-body-1 company-data-left d-inline-block"
                  v-if="!editMode"
                >
                  {{ customer.contact.email }}
                </v-card-text>
                <v-card-text
                  class="pb-1 text-md-body-1 company-data-left d-inline-block"
                  v-if="editMode"
                >
                  <v-text-field
                    outlined
                    required
                    v-model="email"
                    :rules="emailRules"
                    class="stammdaten-textfield"
                  ></v-text-field>
                </v-card-text>
              </div>
              <div>
                <v-card-text
                  v-if="!editMode"
                  class="pl-0 pb-1 font-weight-bold text-md-body-1 company-label-left d-inline-block"
                >
                  {{ $t("masterData.phone") }}
                </v-card-text>
                <v-card-text
                  v-if="editMode"
                  class="pl-0 pb-1 font-weight-bold text-md-body-1 company-label-left d-inline-block"
                >
                  {{ $t("masterData.phone") }}*
                </v-card-text>
                <v-card-text
                  class="pb-1 text-md-body-1 company-data-left d-inline-block"
                  v-if="!editMode"
                >
                  {{ customer.contact.phone }}
                </v-card-text>
                <v-card-text
                  class="pb-1 text-md-body-1 company-data-left d-inline-block"
                  v-if="editMode"
                >
                  <v-text-field
                    outlined
                    required
                    v-model="phone"
                    :rules="phoneRules"
                    class="stammdaten-textfield"
                  ></v-text-field>
                </v-card-text>
              </div>
              <p style="color: lightgrey" v-if="editMode">
                * {{ $t("masterData.mandatoryField") }}
              </p>
            </v-col>
            <v-col cols="6" class="pr-0 py-0 d-inline text-left">
              <div>
                <v-card-text
                  class="pb-1 font-weight-bold text-md-body-1 company-label-right d-inline-block"
                >
                  {{ $t("masterData.customerNumber") }}
                </v-card-text>
                <v-card-text
                  class="pb-1 text-md-body-1 company-data-right d-inline-block"
                >
                  {{ customer.customerNumber }}
                </v-card-text>
              </div>
              <div v-if="!isTvp">
                <v-card-text
                  class="pb-1 font-weight-bold text-md-body-1 company-label-right d-inline-block"
                >
                  {{ $t("componentDashboard.weeeRegistrationNo") }}
                </v-card-text>
                <v-card-text
                  class="pb-1 text-md-body-1 company-data-right d-inline-block"
                >
                  {{ customer.weeeRegisterNumber }}
                </v-card-text>
              </div>
              <div>
                <v-card-text
                  class="pb-1 font-weight-bold text-md-body-1 company-label-right d-inline-block"
                >
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <span>{{ $t("masterData.yourReferenceNumber") }}</span>
                      <v-icon class="tooltip-icon" v-bind="attrs" v-on="on"
                        >mdi-help-circle</v-icon
                      >
                    </template>
                    <div>
                      <span class="d-block font-weight-bold">{{
                        $t("masterData.specifyReferenceNumber")
                      }}</span>
                      <span class="d-block"
                        >{{
                          $t(
                            "masterData.optionToSpecifyNumberForStatementsPart1"
                          )
                        }}
                        <br />
                        {{
                          $t(
                            "masterData.optionToSpecifyNumberForStatementsPart2"
                          )
                        }}</span
                      >
                    </div>
                  </v-tooltip>
                </v-card-text>
                <v-card-text
                  class="pb-1 text-md-body-1 company-data-right d-inline-block"
                  v-if="!editMode"
                >
                  {{ customer.referenceNumber }}
                </v-card-text>
                <v-card-text
                  class="pb-1 text-md-body-1 company-data-right d-inline-block"
                  v-if="editMode"
                >
                  <v-text-field
                    outlined
                    v-model="referenceNumber"
                    class="stammdaten-textfield"
                  ></v-text-field>
                </v-card-text>
              </div>
            </v-col>
          </v-col>
          <div class="editmode-buttons">
            <v-btn
              v-if="editMode"
              class="save-button"
              background-color="primary"
              rounded
              depressed
              :disabled="safeToSave !== true"
              @click="saveCustomerData"
              >{{ $t("masterData.saveChanges") }}</v-btn
            >
            <v-btn
              v-if="editMode"
              @click="switchEditMode"
              class="cancel-button"
              rounded
              outlined
              >{{ $t("masterData.cancel") }}</v-btn
            >
          </div>
        </v-card>
      </v-container>
      <yearly-forecast
        :isTvp="isTvp"
        :infoEditMode="editMode"
        :key="componentKey"
        v-if="reportType === 1"
        @successfully-submitted="$emit('submission-done')"
      />
      <monthly-report
        :isTvp="isTvp"
        :infoEditMode="editMode"
        :key="componentKey"
        v-if="reportType === 2"
        @successfully-submitted="$emit('submission-done')"
      />
      <yearly-report
        :infoEditMode="editMode"
        :key="componentKey"
        v-if="reportType === 3 && isTvp"
        @successfully-submitted="$emit('submission-done')"
      />
      <quartal-report
        :infoEditMode="editMode"
        :key="componentKey"
        v-if="reportType === 4 && !isTvp"
        @successfully-submitted="$emit('submission-done')"
      />
    </v-container>
    <loading-component :isLoading="inProgress" />
  </v-container>
</template>

<script lang="ts">
import Vue from "vue";
import YearlyForecast from "@/components/YearlyForecast.vue";
import MonthlyReport from "@/components/MonthlyReport.vue";
import YearlyReport from "@/components/YearlyReport.vue";
import QuartalReport from "@/components/QuartalReport.vue";
import LoadingComponent from "@/components/atoms/LoadingComponent.vue";
import { mapState } from "vuex";
import {
  emailRules,
  phoneRules,
  validateEmail,
  validatePhone,
} from "@/helpers";
import { ApiUrl } from "@/config/ApiUrl";
import axios from "axios";

interface CustomerInfo {
  contact: {
    email: string;
    phone: string;
  };
  referenceNumber: string;
}
export default Vue.extend({
  props: {
    isTvp: Boolean,
    reportType: Number,
    customerLinkData: Object,
  },
  name: "Dashboard",
  data: () => {
    return {
      editMode: false,
      phone: "",
      email: "",
      referenceNumber: "",
      emailRules: emailRules,
      phoneRules: phoneRules,
      componentKey: 0,
      inProgress: false,
    };
  },
  components: {
    YearlyForecast,
    MonthlyReport,
    YearlyReport,
    QuartalReport,
    LoadingComponent,
  },
  computed: {
    detailInfo() {
      if (this.reportType === 2) {
        return this.$i18n.t("masterData.monthlyReport");
      } else if (this.reportType === 3) {
        return this.$i18n.t("masterData.yearlyReport");
      } else if (this.reportType === 4) {
        return this.$i18n.t("masterData.quarterlyReport");
      }
      return this.$i18n.t("masterData.yearlyForecast");
    },
    ...mapState({
      customer: (state: any) => state.customerManagement.customer,
    }),
    paragraphs() {
      if (this.reportType === 1 && this.isTvp) {
        return [
          this.$i18n.t("websiteWelcomeText1"),
          this.$i18n.t("websiteWelcomeText2"),
        ];
      } else if (this.reportType === 2 && this.isTvp) {
        return [
          this.$i18n.t("componentDashboard.paragraphs.isTvp.reportType2Part1"),
          this.$i18n.t("componentDashboard.paragraphs.isTvp.reportType2Part2"),
        ];
      } else if (this.reportType === 1 && !this.isTvp) {
        return [
          this.$i18n.t("componentDashboard.paragraphs.isNotTvp.reportType1"),
        ];
      } else if (this.reportType === 2 && !this.isTvp) {
        return [
          this.$i18n.t("componentDashboard.paragraphs.isNotTvp.reportType2"),
        ];
      } else if (this.reportType === 4 && !this.isTvp) {
        return [
          this.$i18n.t("componentDashboard.paragraphs.isNotTvp.reportType4"),
        ];
      } else {
        return "";
      }
    },
    safeToSave() {
      return (
        ((this.email !== this.customer.contact.email && this.email !== "") ||
          (this.phone !== this.customer.contact.phone && this.phone !== "") ||
          this.referenceNumber !== this.customer.referenceNumber) &&
        validateEmail(this.email) &&
        validatePhone(this.phone) &&
        this.inProgress !== true
      );
    },
  },
  methods: {
    switchEditMode() {
      this.editMode = !this.editMode;
      this.email = this.customer.contact.email;
      this.phone = this.customer.contact.phone;
      this.referenceNumber = this.customer.referenceNumber;
      this.componentKey += this.componentKey;
    },
    saveCustomerData() {
      this.inProgress = true;
      const payload: CustomerInfo = {
        contact: {
          email: this.email,
          phone: this.phone,
        },
        referenceNumber: this.referenceNumber,
      };
      this.$store.dispatch("checkMaintenanceStatus").then((status) => {
        if (status !== true) {
          axios({
            url: `${ApiUrl.CUSTOMER_INFO}/${this.customer.id}`,
            method: "patch",
            data: payload,
          })
            .then((apiResponse: any) => {
              if (apiResponse.response && apiResponse.response.data.error) {
                this.$toast.error(
                  this.$t("toastTexts.internalError") as string
                );
                return false;
              } else {
                this.$store.dispatch("loadCustomerData", this.customerLinkData);
                this.editMode = false;
                this.inProgress = false;
                this.componentKey += this.componentKey;
                this.$toast(this.$t("toastTexts.checkMasterData") as string);
              }
            })
            .catch((error) => {
              this.inProgress = false;
              this.$toast.error(this.$t("toastTexts.internalError") as string);
            });
        } else {
          window.location.reload();
        }
      });
    },
  },
});
</script>
<style>
.mass-content {
  width: 75%;
  margin-left: 12.5%;
  min-height: 80vh;
}
.mass-info {
  width: 75%;
  margin-left: 12.5%;
}
.company-label-left {
  width: 25%;
  vertical-align: top;
}
.company-data-left {
  width: 75%;
}
.company-label-right {
  width: 40%;
  vertical-align: top;
}
.company-data-right {
  width: 60%;
}
.title-divider {
  border-width: 1px 1px 1px 1px !important;
}
.v-tooltip__content {
  padding: 20px 30px !important;
  background: white !important;
  color: black !important;
  line-height: 2 !important;
  box-shadow: 0px 7px 29px 0px rgba(100, 100, 111, 0.2) !important;
}
.tooltip-icon {
  padding-left: 5px;
  color: black !important;
}
.stammdaten-textfield {
  width: 300px !important;
  height: 50px !important;
  max-width: 350px;
  max-height: 60px;
}
.stammdaten-textfield .v-input__slot {
  min-height: 32px !important;
  height: 32px;
}
.stammdaten-textfield .v-text-field__details {
  max-height: 20px !important;
}
.stammdaten-textfield .v-text-field__slot {
  height: 32px;
}
.stammdaten-textfield fieldset {
  height: 40px;
  border-color: rgba(0, 0, 0, 0.3);
  box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.1);
}
.editmode-buttons {
  display: grid;
  justify-content: center;
}
.editmode-buttons .v-btn {
  width: 400px;
  height: 45px !important;
  margin-bottom: 20px;
  font-size: 1rem;
  font-weight: bold;
  text-transform: none;
  letter-spacing: normal;
}
.editmode-buttons .cancel-button {
  border-color: #8fedc2 !important;
}
.editmode-buttons .save-button {
  background-color: #8fedc2 !important;
}
</style>
