<template>
  <v-container
    class="ma-0 pa-0"
    :class="{ 'blubb-logo-top': !isMobile }"
    style="min-width: 100%"
  >
    <v-row style="flex-wrap: inherit">
      <v-col cols="7" class="left-block">
        <div style="height: 24vh; width: 100%; margin-left: 6vw"></div>
        <h1 class="display-2 font-weight-bold heading">
          {{ $t("ExpiredLink.headingBlack") }}<br />
          <span class="color-primer white-back">{{
            $t("ExpiredLink.headingGreen")
          }}</span>
        </h1>
        <p class="attention-text">
          {{ $t("ExpiredLink.attentionText1") }} <br />
          {{ $t("ExpiredLink.attentionText2") }} <br />
          {{ $t("ExpiredLink.attentionText3") }}
        </p>
        <p class="phone-text">
          <span class="color-primer d-block phonenumber">
            {{ isTvp ? '+49 2203 89 87 191' : '+49 2203 89 87 747' }}
          </span>
          {{ $t("ExpiredLink.time") }}
        </p>
        <a
          :href="
            isTvp
              ? 'mailto:tvp-mengenmeldung@zentek.de'
              : 'mailto:weee_ear@zentek.de'
          "
          >{{ isTvp ? "tvp-mengenmeldung@zentek.de" : "weee_ear@zentek.de" }}
        </a>
      </v-col>
      <v-col cols="5">
        <v-img class="clock-image" src="../assets/Clock.png"></v-img>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import Vue from "vue";

export default Vue.extend({
  name: "ExpiredLink",
  props: {
    isTvp: Boolean,
  },
  computed: {
    isMobile() {
      return this.$vuetify.breakpoint.width < 768;
    },
  },
});
</script>
<style lang="sass" scoped>
.blubb-logo-top
  height: 80vh
  background-image: url("../assets/Blubb.png")
  background-repeat: no-repeat
  background-size: contain
  background-position: top right
  position: relative
  top: -100px
  right: -60px
.left-block
  padding-left: 25%
  text-align: left
.left-block a
  text-decoration: none
  font-size: 1.4rem
.heading
  line-height: 1
  margin-left: 25px
  text-align: left
  margin: 20px 0 40px 0
.attention-text
  text-align: left
  font-size: 1.5rem !important
  line-height: 2
.phone-text
  text-align: left
  display: block
  font-size: 0.8rem
.phonenumber
  font-size: 1.5rem !important
.clock-image
  width: 500px
  height: 610px
  margin-top: 150px
  margin-right: 50px
  align-items: left
</style>
