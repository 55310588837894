<template>
  <v-container class="table-container ma-0 pa-0 pt-16">
    <v-card
      class="mx-0 px-0 pb-16"
      flat
      v-if="materials !== null && materials.length && materials.length > 0"
    >
      <v-card-title class="headline font-weight-bold px-0 mx-0 pb-6"
        ><v-icon color="black" class="pl-0 pr-4" size="60px"
          >$vuetify.icons.two</v-icon
        >
        {{ $t("DashboardSectionTwoHeading.devicesThatCanBeUsedin") }}
        <span class="colored-text">
          {{ $t("DashboardSectionTwoHeading.private") }} </span
        >{{ $t("DashboardSectionTwoHeading.households") }}
      </v-card-title>
      <v-data-table
        :headers="weeeHeaders"
        :items="materials"
        item-key="id"
        :items-per-page="-1"
        class="elevation-4 amount-table"
        :hide-default-footer="true"
      >
        <template v-slot:body="{ items }">
          <tbody>
            <tr v-for="item in items" :key="item.id">
              <td class="text-md-body-1 pl-5 amount-table-border">
                {{ $t(`materials.${item.materialNumber}`) }}
              </td>
              <template v-if="materialAmounts.length > 0">
                <template v-for="(materialAmount, index) in materialAmounts">
                  <td
                    class="text-md-body-1 text-right px-0 max-170"
                    :key="`a${index}`"
                    v-if="materialAmount.materialId === item.materialId"
                  >
                    <v-text-field
                      v-model="materialAmount.month1Amount"
                      class="text-md-body-1 text-right text-field-td"
                      :rules="decimalRules"
                      dense
                      outlined
                      flat
                      solo
                      suffix="kg"
                      hide-details="auto"
                      :placeholder="numberPlaceholder"
                      @keypress.native="checkUserInput"
                      @focus="handleFocus"
                    ></v-text-field>
                  </td>
                  <td
                    class="text-md-body-1 text-right px-0 max-170"
                    :key="`b${index}`"
                    v-if="materialAmount.materialId === item.materialId"
                  >
                    <v-text-field
                      v-model="materialAmount.month2Amount"
                      class="text-md-body-1 text-right text-field-td"
                      :rules="decimalRules"
                      dense
                      outlined
                      flat
                      solo
                      suffix="kg"
                      hide-details="auto"
                      :placeholder="numberPlaceholder"
                      @keypress.native="checkUserInput"
                      @focus="handleFocus"
                    ></v-text-field>
                  </td>
                  <td
                    class="text-md-body-1 text-right px-0 max-170"
                    :key="`c${index}`"
                    v-if="materialAmount.materialId === item.materialId"
                  >
                    <v-text-field
                      v-model="materialAmount.month3Amount"
                      class="text-md-body-1 text-right text-field-td"
                      :rules="decimalRules"
                      dense
                      outlined
                      flat
                      solo
                      suffix="kg"
                      hide-details="auto"
                      :placeholder="numberPlaceholder"
                      @keypress.native="checkUserInput"
                      @focus="handleFocus"
                    ></v-text-field>
                  </td>
                  <td
                    class="text-md-body-1 text-right pr-5 total-td font-weight-bold"
                    :key="`d${index}`"
                    v-if="materialAmount.materialId === item.materialId"
                  >
                    {{
                      calculateTotal(
                        materialAmount.month1Amount,
                        materialAmount.month2Amount,
                        materialAmount.month3Amount
                      )
                    }}
                    kg
                  </td>
                </template>
              </template>
            </tr>
          </tbody>
        </template>
      </v-data-table>
      <span class="hint-text mx-0 mt-2 text-right pr-0 d-block">
        {{ $t("decimalHint") }}
      </span>
    </v-card>
    <div class="button-width mx-auto my-10 px-0 py-10">
      <v-btn
        color="primary"
        class="send-button"
        rounded
        depressed
        x-large
        width="100%"
        height="65px"
        @click="checkForZero"
        :disabled="infoEditMode || !safeToSave"
        ><v-icon size="40px" class="mr-2"> $vuetify.icons.sendButton </v-icon>
        {{ $t("btnReportQuantities") }}</v-btn
      >
      <span class="hint-text hint-middle mx-auto mt-4 text-center d-block">{{
        $t("btnClickDescription")
      }}</span>
    </div>
    <loading-component :isLoading="submitClicked" />
    <v-dialog v-model="isZero" max-width="600" overlay-color="white" persistent>
      <zero-warning @submit-zero="sendData" @close-me="isZero = false" />
    </v-dialog>
  </v-container>
</template>
<script lang="ts">
import Vue from "vue";
import {
  germanDecimalRules,
  convertLocalNumberStringToFloat,
  englishDecimalRules,
  validateGermanDecimals,
  validateEnglishDecimals,
} from "../helpers";
import { mapState } from "vuex";
import axios from "axios";
import { ApiUrl } from "@/config/ApiUrl";
import LoadingComponent from "@/components/atoms/LoadingComponent.vue";
import ZeroWarning from "./atoms/ZeroWarning.vue";

interface QuartalReportRequest {
  customerId: number;
  reportId: string;
  reportType: string;
  productType: string;
  quarterId: number;
  month1materialVolumes: [
    {
      materialId: number;
      unit: string;
      amount: number;
    }
  ];
  month2materialVolumes: [
    {
      materialId: number;
      unit: string;
      amount: number;
    }
  ];
  month3materialVolumes: [
    {
      materialId: number;
      unit: string;
      amount: number;
    }
  ];
  businessYear: number;
  submittedDate: string;
}
export default Vue.extend({
  name: "QuartalReport",
  props: {
    infoEditMode: Boolean,
  },
  components: {
    LoadingComponent,
    ZeroWarning,
  },
  data: () => {
    return {
      inputMaterials: [] as any,
      weeeHeaders: [
        /* header for WEEE Tables*/
        {
          text: "Gerätearten",
          align: "start",
          class: "amount-table-header pl-5 amount-table-border",
          sortable: false,
        },
        {
          text: "firstMonth",
          align: "center",
          class: "amount-table-header",
          sortable: false,
        },
        {
          text: "secondMonth",
          align: "center",
          class: "amount-table-header",
          sortable: false,
        },
        {
          text: "thirdMonth",
          align: "center",
          class: "amount-table-header",
          sortable: false,
        },
        {
          text: "Gesamt",
          align: "center",
          class: "amount-table-header",
          sortable: false,
        },
      ],
      materialAmounts: [] as any,
      firstMonth: "",
      secondMonth: "",
      thirdMonth: "",
      submitClicked: false,
      isZero: false,
    };
  },
  computed: {
    ...mapState({
      customerId: (state: any) => state.customerManagement.customer.id,
      reportType: (state: any) =>
        state.customerManagement.notification.reportType,
      productType: (state: any) =>
        state.customerManagement.notification.productType,
      materials: (state: any) => state.customerManagement.customer.materials,
      businessYear: (state: any) =>
        state.customerManagement.notification.businessYear,
      quarterId: (state: any) =>
        state.customerManagement.notification.quarterId,
      reportId: (state: any) => state.customerManagement.notification.reportId,
    }),
    locale() {
      if (this.$i18n.locale === "en") {
        return "en-GB";
      }
      return "de-DE";
    },
    numberPlaceholder() {
      if (this.$i18n.locale === "en") {
        return "0.000";
      }
      return "0,000";
    },
    decimalRules() {
      if (this.$i18n.locale === "en") {
        return englishDecimalRules;
      }
      return germanDecimalRules;
    },
    safeToSave() {
      let isValid = true;
      for (const materialAmount of this.materialAmounts) {
        const month1Valid =
          this.$i18n.locale === "de"
            ? validateGermanDecimals(materialAmount.month1Amount)
            : validateEnglishDecimals(materialAmount.month1Amount);
        const month2Valid =
          this.$i18n.locale === "de"
            ? validateGermanDecimals(materialAmount.month2Amount)
            : validateEnglishDecimals(materialAmount.month2Amount);
        const month3Valid =
          this.$i18n.locale === "de"
            ? validateGermanDecimals(materialAmount.month3Amount)
            : validateEnglishDecimals(materialAmount.month3Amount);
        if (!month1Valid || !month2Valid || !month3Valid) {
          isValid = false;
        }
      }
      return isValid;
    },
  },
  mounted() {
    const materialAmounts = [] as any;
    for (const material of this.materials) {
      const materialAmount = {
        materialId: material.materialId,
        unit: "kg",
        month1Amount: "",
        month2Amount: "",
        month3Amount: "",
      };
      materialAmounts.push(materialAmount);
    }
    this.materialAmounts = materialAmounts;
    let firstDate = new Date();
    let secondDate = new Date();
    let thirdDate = new Date();
    switch (this.quarterId) {
      case 1:
        {
          firstDate.setMonth(0);
          secondDate.setMonth(1);
          thirdDate.setMonth(2);
        }
        break;
      case 2:
        {
          firstDate.setMonth(3);
          secondDate.setMonth(4);
          thirdDate.setMonth(5);
        }
        break;
      case 3:
        {
          firstDate.setMonth(6);
          secondDate.setMonth(7);
          thirdDate.setMonth(8);
        }
        break;
      case 4:
        {
          firstDate.setMonth(9);
          secondDate.setMonth(10);
          thirdDate.setMonth(11);
        }
        break;
    }
    this.firstMonth = firstDate.toLocaleString(
      this.$i18n.locale === "de" ? "de-DE" : "en-GB",
      { month: "long" }
    );
    this.secondMonth = secondDate.toLocaleString(
      this.$i18n.locale === "de" ? "de-DE" : "en-GB",
      { month: "long" }
    );
    this.thirdMonth = thirdDate.toLocaleString(
      this.$i18n.locale === "de" ? "de-DE" : "en-GB",
      { month: "long" }
    );
    this.weeeHeaders[1].text = this.firstMonth;
    this.weeeHeaders[2].text = this.secondMonth;
    this.weeeHeaders[3].text = this.thirdMonth;
    this.weeeHeaders[0].text = this.$i18n.t(
      "DashboardTableHeader.deviceTypes"
    ) as string;
    this.weeeHeaders[4].text = this.$i18n.t(
      "DashboardTableHeader.total"
    ) as string;
  },
  watch: {
    "$i18n.locale": function (newVal, oldVal) {
      let firstDate = new Date();
      let secondDate = new Date();
      let thirdDate = new Date();
      switch (this.quarterId) {
        case 1:
          {
            firstDate.setMonth(0);
            secondDate.setMonth(1);
            thirdDate.setMonth(2);
          }
          break;
        case 2:
          {
            firstDate.setMonth(3);
            secondDate.setMonth(4);
            thirdDate.setMonth(5);
          }
          break;
        case 3:
          {
            firstDate.setMonth(6);
            secondDate.setMonth(7);
            thirdDate.setMonth(8);
          }
          break;
        case 4:
          {
            firstDate.setMonth(9);
            secondDate.setMonth(10);
            thirdDate.setMonth(11);
          }
          break;
      }
      this.firstMonth = firstDate.toLocaleString(
        newVal === "de" ? "de-DE" : "en-GB",
        { month: "long" }
      );
      this.secondMonth = secondDate.toLocaleString(
        newVal === "de" ? "de-DE" : "en-GB",
        { month: "long" }
      );
      this.thirdMonth = thirdDate.toLocaleString(
        newVal === "de" ? "de-DE" : "en-GB",
        { month: "long" }
      );
      this.weeeHeaders[1].text = this.firstMonth;
      this.weeeHeaders[2].text = this.secondMonth;
      this.weeeHeaders[3].text = this.thirdMonth;
      this.weeeHeaders[0].text = this.$i18n.t(
        "DashboardTableHeader.deviceTypes"
      ) as string;
      this.weeeHeaders[4].text = this.$i18n.t(
        "DashboardTableHeader.total"
      ) as string;
    },
  },
  methods: {
    localize(value: any) {
      if (this.$i18n.locale === "en") {
        return value.replace(/,/g, "");
      } else {
        return value.replace(/\./g, "");
      }
    },
    checkUserInput($userInput: any) {
      const keyCode = $userInput.keyCode ? $userInput.keyCode : $userInput.key;

      // allow comma: 44 and dot: 46
      if (keyCode !== 44 && keyCode !== 46) {
        // allow numbers
        if (keyCode < 48 || keyCode > 57) {
          $userInput.preventDefault();
        }
      }
    },
    handleFocus() {
      if (this.infoEditMode !== false) {
        this.$vuetify.goTo("#customerInfo");
        this.$toast.error(
          this.$t("toastTexts.saveOrDiscardChangesBeforeContinuing") as string
        );
      }
    },
    calculateTotal(first: any, second: any, third: any) {
      const sum =
        convertLocalNumberStringToFloat(
          this.locale,
          first === "" ? "0" : first
        ) +
        convertLocalNumberStringToFloat(
          this.locale,
          second === "" ? "0" : second
        ) +
        convertLocalNumberStringToFloat(
          this.locale,
          third === "" ? "0" : third
        );
      let sumInString = sum.toLocaleString(this.locale, {
        minimumFractionDigits: 1,
        maximumFractionDigits: 3,
      });
      if (this.$i18n.locale === "en") {
        sumInString = sumInString.replace(/,/g, "");
      } else {
        sumInString = sumInString.replace(/\./g, "");
      }
      return sumInString;
    },
    checkForZero() {
      let zeroAmount = true;
      for (const materialAmount of this.materialAmounts) {
        if (
          materialAmount.month1Amount !== "0" &&
          materialAmount.month1Amount !== "" &&
          materialAmount.month1Amount !== "0.000" &&
          materialAmount.month1Amount !== "0,000"
        ) {
          zeroAmount = false;
          break;
        } else if (
          materialAmount.month2Amount !== "0" &&
          materialAmount.month2Amount !== "" &&
          materialAmount.month2Amount !== "0.000" &&
          materialAmount.month2Amount !== "0,000"
        ) {
          zeroAmount = false;
          break;
        } else if (
          materialAmount.month3Amount !== "0" &&
          materialAmount.month3Amount !== "" &&
          materialAmount.month3Amount !== "0.000" &&
          materialAmount.month3Amount !== "0,000"
        ) {
          zeroAmount = false;
          break;
        }
      }
      if (zeroAmount !== false) {
        this.isZero = true;
      } else {
        this.sendData();
      }
    },
    sendData() {
      this.isZero = false;
      this.submitClicked = true;
      const month1materialVolumes = [] as any;
      const month2materialVolumes = [] as any;
      const month3materialVolumes = [] as any;
      const finalMaterialAmounts = JSON.parse(
        JSON.stringify(this.materialAmounts)
      );
      for (const materialAmount of finalMaterialAmounts) {
        const month1materialVolume = {
          materialId: materialAmount.materialId,
          unit: materialAmount.unit,
          amount: convertLocalNumberStringToFloat(
            this.locale,
            materialAmount.month1Amount === ""
              ? "0"
              : materialAmount.month1Amount
          ),
        };
        const month2materialVolume = {
          materialId: materialAmount.materialId,
          unit: materialAmount.unit,
          amount: convertLocalNumberStringToFloat(
            this.locale,
            materialAmount.month2Amount === ""
              ? "0"
              : materialAmount.month2Amount
          ),
        };
        const month3materialVolume = {
          materialId: materialAmount.materialId,
          unit: materialAmount.unit,
          amount: convertLocalNumberStringToFloat(
            this.locale,
            materialAmount.month3Amount === ""
              ? "0"
              : materialAmount.month3Amount
          ),
        };
        month1materialVolumes.push(month1materialVolume);
        month2materialVolumes.push(month2materialVolume);
        month3materialVolumes.push(month3materialVolume);
      }
      const payload: QuartalReportRequest = {
        customerId: this.customerId,
        reportId: this.reportId,
        reportType: this.reportType,
        productType: this.productType,
        month1materialVolumes: month1materialVolumes,
        month2materialVolumes: month2materialVolumes,
        month3materialVolumes: month3materialVolumes,
        businessYear: this.businessYear,
        quarterId: this.quarterId,
        submittedDate: new Date().toLocaleDateString("de-DE", {
          day: "2-digit",
          month: "2-digit",
          year: "numeric",
        }),
      };
      /* AXIOS POST REQUEST HERE */
      this.$store.dispatch("checkMaintenanceStatus").then((status) => {
        if (status !== true) {
          axios({
            url: ApiUrl.REPORT_SUBMIT,
            method: "POST",
            data: payload,
          })
            .then((apiResponse: any) => {
              if (apiResponse.response && apiResponse.response.data.error) {
                this.submitClicked = false;
                this.$toast.error(
                  this.$t("toastTexts.internalError") as string
                );
                return false;
              } else {
                this.submitClicked = false;
                this.$emit("successfully-submitted");
              }
            })
            .catch((error) => {
              this.submitClicked = false;
              this.$toast.error(this.$t("toastTexts.internalError") as string);
            });
        } else {
          window.location.reload();
        }
      });
    },
  },
});
</script>
<style scoped>
.table-container {
  width: 100%;
}
.text-field-td {
  width: 70%;
  margin-left: auto;
  margin-right: auto;
}
.text-field-td >>> input {
  text-align: right;
}
.text-field-td >>> .v-text-field__suffix {
  color: grey;
}
.v-text-field--outlined:not(.v-input--is-focused) >>> fieldset {
  border-color: rgba(0, 0, 0, 0.1);
  box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.1);
}
.text-field-td >>> .v-text-field__details {
  margin-bottom: 0px;
  max-width: 100%;
  width: 100%;
  font-size: 0.3rem !important;
}
.device-select >>> .v-text-field__details {
  display: none;
}
.text-field-td >>> .v-messages {
  font-size: 11px;
  height: 11px;
}
.button-width {
  width: 40%;
}
.send-button >>> span.v-btn__content {
  color: black;
  text-transform: none;
  font-weight: bold;
}
.send-button {
  border-radius: 42px;
}
.button-width .hint-middle {
  width: 80%;
}
.max-170 {
  max-width: 170px;
}
</style>
