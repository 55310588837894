<template>
  <v-card class="px-12 rounded-lg">
    <v-icon
      @click="$emit('close-this')"
      style="
        color: black;
        position: absolute !important;
        right: 16px !important;
        font-size: 2.5rem;
        margin-top: 16px !important;
      "
      >mdi-close-circle-outline</v-icon
    >
    <v-card-title
      class="display-1 font-weight-bold mt-8 pb-8 text-left text-uppercase"
    >
      {{ $t("atomComponentCopyRight.imprint") }}
    </v-card-title>
    <v-card-text class="text-left black--text ln-1 mb-4">
      <p>
        {{ $t("atomComponentCopyRight.imprintTextPart1") }}<br />{{
          $t("atomComponentCopyRight.imprintTextPart2")
        }}
      </p>
      <p class="mt-8">
        <span class="font-weight-bold label-left">{{
          $t("atomComponentCopyRight.companyName")
        }}</span>
        <span>Zentek Services GmbH & Co. KG</span>
        <br />
        <span class="font-weight-bold label-left">{{
          $t("atomComponentCopyRight.address")
        }}</span>
        <span>Ettore-Bugatti-Str. 6-14</span>
        <br />
        <span class="font-weight-bold label-left"></span>
        <span>51149 Köln</span>
      </p>
      <p class="mt-8">
        <span class="font-weight-bold label-left">{{
          $t("atomComponentCopyRight.phone")
        }}</span>
        <span>+49 2203 8987-191</span>
        <br />
        <span class="font-weight-bold label-left">Fax</span>
        <span>+49 2203 8987-999</span>
        <br />
        <span class="font-weight-bold label-left">E-Mail</span>
        <span
          ><a href="mailto:info@zentek.de" class="no-deco"
            >info@zentek.de</a
          ></span
        >
      </p>
      <p class="mt-8">
        <span class="font-weight-bold label-left">{{
          $t("atomComponentCopyRight.commercialRegister")
        }}</span>
        <span>{{ $t("atomComponentCopyRight.commercialRegisterValue") }}</span>
        <br />
        <span class="font-weight-bold label-left">{{
          $t("atomComponentCopyRight.registrationNumber")
        }}</span>
        <span>HRA Köln 33824</span>
        <br />
        <span class="font-weight-bold label-left">{{
          $t("atomComponentCopyRight.VatNo")
        }}</span>
        <span>DE 321 853 018</span>
      </p>
      <p class="mt-8">
        <span class="font-weight-bold">{{
          $t("atomComponentCopyRight.certificationBody")
        }}</span
        ><br />
        {{ $t("atomComponentCopyRight.certificationBodyValue") }}
        <a href="https://www.zer-qms.de/" target="_blank" class="no-deco"
          >https://www.zer-qms.de/</a
        >
      </p>
    </v-card-text>
  </v-card>
</template>
<script lang="ts">
import Vue from "vue";
export default Vue.extend({
  data: () => {
    return {};
  },
});
</script>
<style lang="sass" scoped>
.ln-1
  line-height: 1.5rem
.label-left
  display: inline-block
  width: 150px
</style>
