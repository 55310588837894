<template>
  <v-dialog v-model="isLoading" fullscreen>
    <v-container
      fluid
      fill-height
      style="background-color: rgba(239, 239, 239, 0.5)"
    >
      <v-layout justify-center align-center>
        <v-progress-circular indeterminate color="primary">
        </v-progress-circular>
      </v-layout>
    </v-container>
  </v-dialog>
</template>

<script>
import Vue from "vue";

export default Vue.extend({
  name: "LoadingComponent",
  props: {
    isLoading: {
      type: Boolean,
      default: false,
    },
  },
});
</script>

<style></style>
