<template>
  <v-container
    app
    color="transparent"
    :class="[locale, screenClass, 'ma-0', 'pa-0', 'subheader']"
    v-if="!isMobile"
  >
    <v-sheet
      class="sheet-sub mx-auto"
      color="transparent"
      v-if="!successMessage"
    >
      <div class="sub-object">
        <h1 class="display-2 font-weight-bold">
          {{ $t("componentSubDrawerOne.notSuccessMessage.allDonePart1") }}
          <span class="color-primer white-back">{{
            $t("componentSubDrawerOne.notSuccessMessage.allDonePart2")
          }}</span>
        </h1>
        <p class="success-font mt-10">
          {{
            $t("componentSubDrawerOne.notSuccessMessage.reportedSuccessText1")
          }}
          <span v-if="locale === `de`"> {{ submitDate }} </span><br />
          {{
            $t("componentSubDrawerOne.notSuccessMessage.reportedSuccessText2")
          }}
          <template v-if="locale === `en`"> {{ submitDate }} </template><br />
          <br />
          {{ $t("componentSubDrawerOne.notSuccessMessage.reportedSuccessText3")
          }}<br />
          {{ $t("componentSubDrawerOne.notSuccessMessage.reportedSuccessText4")
          }}<br /><br />
          <a :href="'mailto:' + customer.contact.email">{{
            customer.contact.email
          }}</a>
        </p>
      </div>
    </v-sheet>
    <v-sheet v-else class="sheet-sub mx-auto" color="transparent">
      <div class="sub-object">
        <h1 class="display-2 font-weight-bold">
          {{ $t("componentSubDrawerOne.successMessage.thankYouPart1")
          }}<span class="color-primer white-back">{{
            $t("componentSubDrawerOne.successMessage.thankYouPart2")
          }}</span
          ><br />
        </h1>
        <p class="success-font mt-10">
          {{ $t("componentSubDrawerOne.successMessage.thankYouText1") }}<br />
          {{ $t("componentSubDrawerOne.successMessage.thankYouText2") }}<br />
          {{ $t("componentSubDrawerOne.successMessage.thankYouText3") }}<br />
          {{ $t("componentSubDrawerOne.successMessage.thankYouText4")
          }}<br /><br />
          <a :href="'mailto:' + customer.contact.email">{{
            customer.contact.email
          }}</a>
        </p>
      </div>
    </v-sheet>
  </v-container>
</template>
<script lang="ts">
import Vue from "vue";
import { mapState } from "vuex";
export default Vue.extend({
  props: {
    successMessage: Boolean,
  },
  data: () => {
    return {
      successClass: "",
      displayDateDE: false,
      displayDateEN: false,
    };
  },
  mounted() {
    this.$i18n.locale == "de"
      ? (this.displayDateDE = true)
      : (this.displayDateEN = true);
  },
  watch: {
    successMessage(value) {
      if (value !== false) {
        this.successClass = "success-back";
      }
    },
  },
  computed: {
    submitDate() {
      const now = new Date(); //will be changed into submission date
      return now.toLocaleDateString("de-DE");
    },
    isMobile() {
      return this.$vuetify.breakpoint.width < 768;
    },
    locale() {
      return this.$i18n.locale;
    },
    screenClass() {
      return this.successMessage ? "success-back" : "done-banner";
    },
    ...mapState({
      customer: (state: any) => state.customerManagement.customer,
    }),
  },
});
</script>
<style scoped>
.done-banner {
  background-image: url("../assets/Submitted_Background.svg");
  background-repeat: no-repeat;
  background-position: right top;
  background-size: contain;
}
.en.done-banner {
  background-image: url("../assets/Submitted_Background_english.svg");
  background-repeat: no-repeat;
  background-position: right top;
  background-size: contain;
}
.success-back {
  min-height: 100vh;
  background-image: url("../assets/Success_Animated.svg");
  background-repeat: no-repeat;
  background-position: right;
  background-size: contain;
}
.en.success-back {
  min-height: 100vh;
  background-image: url("../assets/Success_Animated_english.svg");
  background-repeat: no-repeat;
  background-position: right;
  background-size: contain;
}
.success-container >>> a {
  text-decoration: none;
}
.subheader {
  min-height: 100vh;
  min-width: 100%;
}
.sheet-sub {
  margin-top: 15%;
  max-width: 1600px;
}
.sub-object {
  width: 75%;
  margin-left: 12.5%;
}
.success-font {
  font-size: 1.5rem;
}
@media only screen and (min-width: 2000px) {
  .subheader {
    min-height: 80vh;
  }
}
@media only screen and (min-width: 3000px) {
  .subheader {
    min-height: 60vh;
  }
}
@media only screen and (min-width: 5000px) {
  .subheader {
    min-height: 50vh;
  }
}
@media only screen and (max-width: 1400px) {
  .white-back {
    background: white;
  }
}
@media only screen and (max-width: 1280px) {
  .display-2 {
    font-size: 2.5rem !important;
  }
}
@media only screen and (max-width: 1024px) {
  .display-2 {
    font-size: 2.25rem !important;
  }
  .success-font {
    font-size: 1rem;
  }
  @media (max-height: 800px) {
    .display-2 {
      font-size: 2rem !important;
    }
    .success-font {
      font-size: 0.85rem;
    }
  }
}
</style>
