export const VuetifyToastOptions = {
  x: "center", // default
  y: "bottom", // default
  color: "toast-success", // default
  icon: "",
  iconColor: "primary", // default
  classes: ["body-2 light my-toast"],
  timeout: 3000, // default
  dismissable: true, // default
  multiLine: false, // default
  vertical: false, // default
  queueable: true, // default
  showClose: false, // default
  closeText: "", // default
  closeIcon: "mdi-close", // default
  closeColor: "", // default
  slot: [], //default
  shorts: {
    custom: {
      color: "purple",
    },
  },
  property: "$toast", // default
};
