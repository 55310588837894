import { render, staticRenderFns } from "./QuartalReport.vue?vue&type=template&id=f581137c&scoped=true"
import script from "./QuartalReport.vue?vue&type=script&lang=ts"
export * from "./QuartalReport.vue?vue&type=script&lang=ts"
import style0 from "./QuartalReport.vue?vue&type=style&index=0&id=f581137c&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f581137c",
  null
  
)

export default component.exports