<template>
  <v-container class="table-container ma-0 pa-0 pt-16">
    <v-card
      class="mx-0 px-0 pb-16"
      flat
      v-if="
        nonDangerousMaterials &&
        nonDangerousMaterials.length &&
        nonDangerousMaterials.length > 0
      "
    >
      <v-card-title
        v-if="isTvp"
        class="headline font-weight-bold px-0 mx-0 pb-6"
        ><v-icon color="black" class="pl-0 pr-4" size="60px"
          >$vuetify.icons.two</v-icon
        >
        {{ $t("DashboardSectionTwoHeading.packagingMaterial") }}
        <span class="colored-text">
          {{ $t("DashboardSectionTwoHeading.without") }} </span
        >{{ $t("DashboardSectionTwoHeading.hazardousResidues") }}
      </v-card-title>
      <v-card-title
        v-if="!isTvp"
        class="headline font-weight-bold px-0 mx-0 pb-6"
        ><v-icon color="black" class="pl-0 pr-4" size="60px"
          >$vuetify.icons.two</v-icon
        >
        {{ $t("DashboardSectionTwoHeading.devicesThatCanBeUsedin") }}
        <span class="colored-text">{{
          $t("DashboardSectionTwoHeading.private")
        }}</span
        >{{ $t("DashboardSectionTwoHeading.households") }}
      </v-card-title>
      <v-data-table
        v-if="isTvp"
        :headers="tvpHeaders"
        :items="nonDangerousMaterials"
        item-key="id"
        :items-per-page="-1"
        class="elevation-4 amount-table"
        :hide-default-footer="true"
      >
        <template v-slot:body="{ items }">
          <tbody>
            <tr v-for="item in items" :key="item.materialId">
              <td class="text-md-body-1 pl-10">
                {{ $t(`materials.${item.materialNumber}`) }}
              </td>
              <td class="text-md-body-1 text-center amount-table-border">
                {{ item.avv }}
              </td>
              <template
                v-for="lastYearMaterial in lastYearReport.materialVolumes"
              >
                <td
                  class="text-md-body-1 pl-4 pr-16 text-right amount-table-border"
                  v-if="lastYearMaterial.materialId === item.materialId"
                  :key="lastYearMaterial.materialId"
                >
                  {{
                    lastYearMaterial.forecastAmount &&
                    lastYearMaterial.forecastAmount !== null
                      ? localize(
                          lastYearMaterial.forecastAmount.toLocaleString(locale)
                        )
                      : "0"
                  }}
                  {{ lastYearMaterial.unit }}
                </td>
              </template>
              <td class="text-md-body-1 text-center">
                <template v-for="materialAmount in materialAmounts">
                  <v-text-field
                    v-model="materialAmount.forecastAmount"
                    class="text-md-body-1 text-right text-field-td"
                    :rules="decimalRules"
                    dense
                    outlined
                    flat
                    solo
                    suffix="t"
                    hide-details="auto"
                    v-if="materialAmount.materialId === item.materialId"
                    :key="materialAmount.materialId"
                    :placeholder="numberPlaceholder"
                    @keypress.native="checkUserInput"
                    @focus="handleFocus"
                  ></v-text-field>
                </template>
              </td>
            </tr>
          </tbody>
        </template>
      </v-data-table>

      <v-data-table
        v-if="!isTvp"
        :headers="weeeHeaders"
        :items="nonDangerousMaterials"
        item-key="id"
        :items-per-page="-1"
        class="elevation-4 amount-table"
        :hide-default-footer="true"
      >
        <template v-slot:body="{ items }">
          <tbody>
            <tr v-for="item in items" :key="item.materialId">
              <td class="text-md-body-1 pl-10">
                {{ $t(`materials.${item.materialNumber}`) }}
              </td>
              <template
                v-for="lastYearMaterial in lastYearReport.materialVolumes"
              >
                <td
                  class="text-md-body-1 pl-4 pr-16 text-right amount-table-border"
                  v-if="lastYearMaterial.materialId === item.materialId"
                  :key="lastYearMaterial.materialId"
                >
                  {{
                    lastYearMaterial.amount && lastYearMaterial.amount !== null
                      ? localize(lastYearMaterial.amount.toLocaleString(locale))
                      : "0"
                  }}
                  {{ lastYearMaterial.unit }}
                </td>
              </template>
              <td class="text-md-body-1 text-center">
                <template v-for="materialAmount in materialAmounts">
                  <v-text-field
                    v-model="materialAmount.forecastAmount"
                    class="text-md-body-1 text-right text-field-td"
                    :rules="decimalRules"
                    dense
                    outlined
                    flat
                    solo
                    suffix="kg"
                    hide-details="auto"
                    v-if="materialAmount.materialId === item.materialId"
                    :key="materialAmount.materialId"
                    :placeholder="numberPlaceholder"
                    @keypress.native="checkUserInput"
                    @focus="handleFocus"
                  ></v-text-field>
                </template>
              </td>
            </tr>
          </tbody>
        </template>
      </v-data-table>
      <span class="hint-text mx-0 mt-2 text-right pr-0 d-block">
        {{ $t("decimalHint") }}
      </span>
    </v-card>
    <v-card
      class="mx-0 px-0"
      flat
      :class="
        !nonDangerousMaterials ||
        !nonDangerousMaterials.length ||
        nonDangerousMaterials.length === 0
          ? 'pb-16'
          : 'py-16'
      "
      v-if="
        isTvp &&
        dangerousMaterials &&
        dangerousMaterials.length &&
        dangerousMaterials.length > 0
      "
    >
      <v-card-title class="headline font-weight-bold px-0 mx-0"
        ><v-icon color="black" class="pl-0 pr-4" size="60px">{{
          !nonDangerousMaterials.length || nonDangerousMaterials.length === 0
            ? "$vuetify.icons.two"
            : "$vuetify.icons.three"
        }}</v-icon>
        {{ $t("DashboardSectionTwoHeading.packagingMaterial") }}
        <span class="colored-text">
          {{ $t("DashboardSectionTwoHeading.with") }} </span
        >{{ $t("DashboardSectionTwoHeading.hazardousResiduesn") }}
      </v-card-title>
      <v-data-table
        :headers="tvpHeaders"
        :items="dangerousMaterials"
        item-key="id"
        :items-per-page="-1"
        class="elevation-4 amount-table"
        :hide-default-footer="true"
      >
        <template v-slot:body="{ items }">
          <tbody>
            <tr v-for="item in items" :key="item.materialId">
              <td class="text-md-body-1 pl-10">
                {{ $t(`materials.${item.materialNumber}`) }}
              </td>
              <td class="text-md-body-1 text-center amount-table-border">
                {{ item.avv }}
              </td>
              <template
                v-for="lastYearMaterial in lastYearReport.materialVolumes"
              >
                <td
                  class="text-md-body-1 pl-4 pr-16 text-right amount-table-border"
                  v-if="lastYearMaterial.materialId === item.materialId"
                  :key="lastYearMaterial.materialId"
                >
                  {{
                    lastYearMaterial.forecastAmount &&
                    lastYearMaterial.forecastAmount !== null
                      ? localize(
                          lastYearMaterial.forecastAmount.toLocaleString(locale)
                        )
                      : "0"
                  }}
                  {{ lastYearMaterial.unit }}
                </td>
              </template>
              <td class="text-md-body-1 text-center">
                <template v-for="materialAmount in materialAmounts">
                  <v-text-field
                    v-model="materialAmount.forecastAmount"
                    class="text-md-body-1 text-right text-field-td"
                    :rules="decimalRules"
                    dense
                    outlined
                    flat
                    solo
                    suffix="t"
                    hide-details="auto"
                    v-if="materialAmount.materialId === item.materialId"
                    :key="materialAmount.materialId"
                    :placeholder="numberPlaceholder"
                    @focus="handleFocus"
                  ></v-text-field>
                </template>
              </td>
            </tr>
          </tbody>
        </template>
      </v-data-table>
      <v-row>
        <v-col cols="6" class="hint-text mx-0 mt-2 text-left"
          >*{{ $t("DashboardSectionTwoHeading.with") }}
          {{ $t("DashboardSectionTwoHeading.hazardousResiduesn") }}</v-col
        >
        <v-col cols="6" class="hint-text mt-2 text-right mr-0 ml-auto">
          {{ $t("decimalHint") }}
        </v-col>
      </v-row>
    </v-card>
    <div class="button-width mx-auto my-12 px-0 py-10">
      <v-btn
        color="primary"
        class="send-button"
        rounded
        depressed
        x-large
        width="100%"
        height="65px"
        @click="checkForZero"
        :disabled="infoEditMode || !safeToSave"
        ><v-icon size="40px" class="mr-2"> $vuetify.icons.sendButton </v-icon>
        {{ $t("btnReportQuantities") }}</v-btn
      >
      <span class="hint-text hint-middle mx-auto mt-4 text-center d-block">{{
        $t("btnClickDescription")
      }}</span>
    </div>
    <loading-component :isLoading="submitClicked" />
    <v-dialog v-model="isZero" max-width="600" overlay-color="white" persistent>
      <zero-warning @submit-zero="sendData" @close-me="isZero = false" />
    </v-dialog>
  </v-container>
</template>

<script lang="ts">
import Vue from "vue";
import {
  germanDecimalRules,
  convertLocalNumberStringToFloat,
  englishDecimalRules,
  validateGermanDecimals,
  validateEnglishDecimals,
} from "../helpers";
import { mapState } from "vuex";
import axios from "axios";
import { ApiUrl } from "@/config/ApiUrl";
import i18n from "../i18n";
import LoadingComponent from "@/components/atoms/LoadingComponent.vue";
import ZeroWarning from "./atoms/ZeroWarning.vue";

interface YearlyForecasReportRequest {
  customerId: number;
  reportId: string;
  reportType: string;
  productType: string;
  materialVolumes: [
    {
      materialId: number;
      unit: string;
      forecastAmount: number;
    }
  ];
  businessYear: number;
  forecastSubmitDate: string;
}
export default Vue.extend({
  props: {
    isTvp: Boolean,
    infoEditMode: Boolean,
  },
  name: "YearlyForecast",
  components: {
    LoadingComponent,
    ZeroWarning,
  },
  data: () => {
    return {
      tvpHeaders: [
        /* header for TVP Tables*/
        {
          text: "Verpackungsmaterial",
          align: "start",
          value: "name",
          class: "amount-table-header pl-10",
          sortable: false,
        },
        {
          text: "AVV",
          align: "center",
          value: "avv",
          class: "amount-table-header amount-table-border",
          sortable: false,
        },
        {
          text: "IST-Menge / Jahr ",
          align: "center",
          value: "currentYearAmount",
          class: "amount-table-header amount-table-border",
          sortable: false,
        },
        {
          text: "Prognose / Jahr ",
          align: "center",
          value: "forecastAmount",
          class: "amount-table-header",
          sortable: false,
        },
      ],
      weeeHeaders: [
        /* header for WEEE Tables*/
        {
          text: "Gerätearten",
          align: "start",
          value: "name",
          class: "amount-table-header pl-10",
          sortable: false,
        },
        {
          text: "IST-Menge / Jahr ",
          align: "end",
          value: "currentYearAmount",
          class: "amount-table-header amount-table-border pr-14",
          sortable: false,
        },
        {
          text: "Prognose / Jahr ",
          align: "center",
          value: "forecastAmount",
          class: "amount-table-header",
          sortable: false,
        },
      ],
      materialAmounts: [] as any,
      currentLanguageSetting: "",
      submitClicked: false,
      isZero: false,
    };
  },
  watch: {
    "$i18n.locale": function (newVal, oldVal) {
      if (this.isTvp) {
        this.tvpHeaders[0].text = this.$i18n.t(
          "DashboardTableHeader.packagingMaterial"
        ) as string;
        this.tvpHeaders[2].text =
          this.$i18n.t("DashboardTableHeader.forecastYear") +
          `${this.businessYear - 1}`;
        this.tvpHeaders[3].text =
          this.$i18n.t("DashboardTableHeader.forecastYear") +
          `${this.businessYear}`;
      } else {
        this.weeeHeaders[0].text = this.$i18n.t(
          "DashboardTableHeader.deviceTypes"
        ) as string;
        this.weeeHeaders[1].text = `${
          this.$i18n.t("DashboardTableHeader.actualQuantity") as string
        } ${this.businessYear - 1}`;
        this.weeeHeaders[2].text = `${
          this.$i18n.t("DashboardTableHeader.forecastYear") as string
        } ${this.businessYear}`;
      }
    },
  },
  computed: {
    ...mapState({
      customerId: (state: any) => state.customerManagement.customer.id,
      reportType: (state: any) =>
        state.customerManagement.notification.reportType,
      productType: (state: any) =>
        state.customerManagement.notification.productType,
      materials: (state: any) => state.customerManagement.customer.materials,
      lastYearReport: (state: any) => state.customerManagement.report,
      businessYear: (state: any) =>
        state.customerManagement.notification.businessYear,
      reportId: (state: any) => state.customerManagement.notification.reportId,
    }),
    dangerousMaterials() {
      let dangerousMaterials = [] as any;
      if (this.isTvp) {
        dangerousMaterials = this.materials.filter((material: any) => {
          return material.avv.includes("*");
        });
      } else {
        dangerousMaterials = this.materials;
      }
      return dangerousMaterials;
    },
    nonDangerousMaterials() {
      let nonDangerousMaterials = [] as any;
      if (this.materials !== null) {
        if (this.isTvp) {
          nonDangerousMaterials = this.materials.filter((material: any) => {
            return !material.avv.includes("*");
          });
        } else {
          nonDangerousMaterials = this.materials;
        }
      }
      return nonDangerousMaterials;
    },
    locale() {
      if (this.$i18n.locale === "en") {
        return "en-GB";
      }
      return "de-DE";
    },
    numberPlaceholder() {
      if (this.$i18n.locale === "en") {
        return "0.000";
      }
      return "0,000";
    },
    decimalRules() {
      if (this.$i18n.locale === "en") {
        return englishDecimalRules;
      }
      return germanDecimalRules;
    },
    safeToSave() {
      let isValid = true;
      for (const materialAmount of this.materialAmounts) {
        const singleValid =
          this.$i18n.locale === "de"
            ? validateGermanDecimals(materialAmount.forecastAmount)
            : validateEnglishDecimals(materialAmount.forecastAmount);
        if (!singleValid) {
          isValid = false;
        }
      }
      return isValid;
    },
  },
  mounted() {
    const materialAmounts = [] as any;
    for (const material of this.materials) {
      const materialAmount = {
        materialId: material.materialId,
        unit: this.isTvp ? "t" : "kg",
        forecastAmount: "" as any,
      };
      materialAmounts.push(materialAmount);
    }
    this.materialAmounts = materialAmounts;
    if (this.isTvp) {
      this.tvpHeaders[0].text = this.$i18n.t(
        "DashboardTableHeader.packagingMaterial"
      ) as string;
      this.tvpHeaders[2].text =
        this.$i18n.t("DashboardTableHeader.forecastYear") +
        `${this.businessYear - 1}`;
      this.tvpHeaders[3].text =
        this.$i18n.t("DashboardTableHeader.forecastYear") +
        `${this.businessYear}`;
    } else {
      this.weeeHeaders[0].text = this.$i18n.t(
        "DashboardTableHeader.deviceTypes"
      ) as string;
      this.weeeHeaders[1].text = `${
        this.$i18n.t("DashboardTableHeader.actualQuantity") as string
      } ${this.businessYear - 1}`;
      this.weeeHeaders[2].text = `${
        this.$i18n.t("DashboardTableHeader.forecastYear") as string
      } ${this.businessYear}`;
    }
  },
  methods: {
    localize(value: any) {
      if (this.$i18n.locale === "en") {
        return value.replace(/,/g, "");
      } else {
        return value.replace(/\./g, "");
      }
    },
    checkUserInput($userInput: any) {
      const keyCode = $userInput.keyCode ? $userInput.keyCode : $userInput.key;

      // allow comma: 44 and dot: 46
      if (keyCode !== 44 && keyCode !== 46) {
        // allow numbers
        if (keyCode < 48 || keyCode > 57) {
          $userInput.preventDefault();
        }
      }
    },
    handleFocus() {
      if (this.infoEditMode !== false) {
        this.$vuetify.goTo("#customerInfo");
        this.$toast.error(
          this.$i18n.t(
            "toastTexts.saveOrDiscardChangesBeforeContinuing"
          ) as string
        );
      }
    },
    checkForZero() {
      let zeroAmount = true;
      for (const materialAmount of this.materialAmounts) {
        if (
          materialAmount.forecastAmount !== "0" &&
          materialAmount.forecastAmount !== "" &&
          materialAmount.forecastAmount !== "0.000" &&
          materialAmount.forecastAmount !== "0,000"
        ) {
          zeroAmount = false;
          break;
        }
      }
      if (zeroAmount !== false) {
        this.isZero = true;
      } else {
        this.sendData();
      }
    },
    sendData() {
      this.isZero = false;
      this.submitClicked = true;
      const finalMaterialAmounts = JSON.parse(
        JSON.stringify(this.materialAmounts)
      );
      for (const materialAmount of finalMaterialAmounts) {
        materialAmount.forecastAmount = convertLocalNumberStringToFloat(
          this.locale,
          materialAmount.forecastAmount === ""
            ? "0"
            : materialAmount.forecastAmount
        );
      }
      const payload: YearlyForecasReportRequest = {
        customerId: this.customerId,
        reportId: this.reportId,
        reportType: this.reportType,
        productType: this.productType,
        materialVolumes: finalMaterialAmounts,
        businessYear: this.businessYear,
        forecastSubmitDate: new Date().toLocaleDateString("de-DE", {
          day: "2-digit",
          month: "2-digit",
          year: "numeric",
        }),
      };
      /* AXIOS POST REQUEST HERE */

      this.$store.dispatch("checkMaintenanceStatus").then((status) => {
        if (status !== true) {
          axios({
            url: ApiUrl.FORECAST_REPORT_SUBMIT,
            method: "POST",
            data: payload,
          })
            .then((apiResponse: any) => {
              if (apiResponse.response && apiResponse.response.data.error) {
                this.submitClicked = false;
                this.$toast.error(
                  this.$t("toastTexts.internalError") as string
                );
                return false;
              } else {
                this.submitClicked = false;
                this.$emit("successfully-submitted");
              }
            })
            .catch((error) => {
              this.submitClicked = false;
              this.$toast.error(this.$t("toastTexts.internalError") as string);
            });
        } else {
          window.location.reload();
        }
      });
    },
  },
});
</script>
<style scoped>
.table-container {
  width: 100%;
}
.text-field-td {
  width: 70%;
  margin-left: auto;
  margin-right: auto;
}
.text-field-td >>> input {
  text-align: right;
}
.text-field-td >>> .v-text-field__suffix {
  color: grey;
}
.text-field-td.v-text-field--outlined:not(.v-input--is-focused) >>> fieldset {
  border-color: rgba(0, 0, 0, 0.1);
  box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.1);
}
.text-field-td >>> .v-text-field__details {
  margin-bottom: 0px;
  max-width: 100%;
  width: 100%;
  font-size: 0.3rem !important;
}
.text-field-td >>> .v-messages {
  font-size: 11px;
  height: 11px;
}
.button-width {
  width: 40%;
}
.send-button >>> span.v-btn__content {
  color: black;
  text-transform: none;
  font-weight: bold;
}
.send-button {
  border-radius: 42px;
}
.button-width .hint-middle {
  width: 80%;
}
</style>
