import axios from "axios";
import { EventBus } from "@/helpers";

axios.interceptors.request.use(
  function (config) {
    // Do something before request is sent
    EventBus.$emit("loading", "_", { detail: true });
    return config;
  },
  function (error) {
    // Do something with request error
    //console.log('axios interceptor: request error')
    EventBus.$emit("loading", "_", { detail: false });
    return Promise.reject(error);
  }
);

axios.interceptors.response.use(
  function (response) {
    EventBus.$emit("loading", "_", { detail: false });
    return response;
  },
  function (error) {
    //console.log('axios interceptor: response error')
    EventBus.$emit("loading", "_", { detail: false });
    if (error.response) {
      return error;
    }
    if (error.response?.status == 500) {
      EventBus.$emit("mass-server-error");
    }
    throw error;
  }
);

export const appModule = {
  state: () => ({
    isLoading: false,
  }),
  mutations: {
    setLoadingState(state: any, isLoading: boolean) {
      state.isLoading = isLoading;
    },
  },
  actions: {
    setLoadingState({ commit }: any, isLoading: boolean) {
      commit("setLoadingState", isLoading);
    },
  },
  getters: {
    isLoading(state: any) {
      return state.isLoading;
    },
  },
};
