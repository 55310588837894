<template>
  <v-container class="pa-0 blubb-logo-bottom" style="height: 100vh">
    <div style="height: 24vh; width: 100%; margin-left: 6vw">
      <div class="logo-container mt-3"></div>
      <div class="blubb-logo-top"></div>
    </div>
    <h1 class="font-weight-bold heading">
      <span class="color-primer white-back">
        {{ $t("MobileView.heading1") }}</span
      ><br />
      {{ $t("MobileView.heading2") }}<br />{{ $t("MobileView.heading3") }}
    </h1>
    <p class="attention-text">
      {{ $t("MobileView.attentionText") }} <br />
      {{ $t("MobileView.thankYou") }}
    </p>

    <div :class="isTvp ? 'laptop-picture-tvp' : 'laptop-picture-weee'"></div>
  </v-container>
</template>
<script lang="ts">
import Vue from "vue";

export default Vue.extend({
  name: "MobileView",
  props: {
    isTvp: Boolean,
  },
});
</script>
<style lang="sass" scoped>
.white-back
  background-color: white

.blubb-logo-top
  background-image: url("../assets/Blubb.png")
  background-repeat: no-repeat
  background-position: right -2.5vh
  background-size: 80% auto
  width: 54vw
  height: 100%
  display: inline-block

.blubb-logo-bottom
  background-image: url("../assets/Blubb_upside.png")
  background-repeat: no-repeat
  background-position: -3vw bottom
  background-size: 70% auto

.logo-container
  width: 45vw
  height: 50px !important
  display: inline-block
  vertical-align: top
.heading
  line-height: 1
  margin-left: 25px
.attention-text
  margin: 30px 0 20px 25px
  line-height: 2
  padding-right: 10px
  z-index: 1
  position: absolute
.laptop-picture-weee
  background-image: url("../assets/WEEE_Motiv.png")
  background-repeat: no-repeat
  background-position: left bottom
  background-size: contain
  height: 60%
  width: 100%
.laptop-picture-tvp
  background-image: url("../assets/TVP_Motiv.png")
  background-repeat: no-repeat
  background-position: left bottom
  background-size: contain
  height: 60%
  width: 100%
</style>
